.service-children

    &-header
        position: relative
        padding: 3rem 0 4rem
        background: linear-gradient(90.14deg, #FFF 45.55%, #f7fcff 62.54%)
        margin-bottom: 2rem

        @include max-width-lg
            background: linear-gradient(180deg, #FFF 44.35%, rgba(255, 255, 255, 0.00) 65.92%)
            min-height: 800px

        &-cover
            position: absolute
            right: 0
            top: 0
            height: 100%
            width: 45%
            margin-right: 5%

            @include max-width-lg
                position: relative
                margin-top: 350px
                width: 100%
                height: 400px
                object-fit: cover
                z-index: 1

                @include max-width-lg
                    margin-top: 0

            &-img
                height: 100%
                width: 100%
                object-fit: cover
                object-position: top
                z-index: 1

                @include max-width-lg
                    z-index: -1

        &-data
            display: flex
            flex-direction: column
            justify-content: space-between

            &-list
                margin: 0.5rem 0 1.5rem
                display: flex
                flex-direction: column
                gap: 1rem

                &-item
                    display: flex
                    align-items: center
                    gap: 7px

                    &-text
                        color: #646468

            &-title
                font-family: Manrope
                color: #333335
                font-size: 32px
                font-weight: 500
                max-width: 400px
                margin: 1rem 0 1.5rem
                line-height: 130%

                @include max-width-lg
                    font-size: 20px

            &-subtitle
                font-size: 18px
                color: #646468
                margin-bottom: 1.5rem

        &-button
            margin-bottom: 2rem

    &-care

        &-wrapper
            display: flex
            flex-wrap: wrap
            justify-content: space-between
            gap: 1.25rem
            margin-top: 2rem

            @include max-width-xl
                flex-direction: column

        &-item
            display: flex
            gap: 1.5rem
            align-items: flex-start
            flex-basis: calc(50% - 1.25rem)
            box-shadow: 6px 6px 12px 0px rgba(217, 217, 217, 0.25)
            background-color: #fff
            border-radius: 8px
            padding: 32px 40px

            @include max-width-md
                flex-direction: column
                flex-basis: 100%

            &-img
                width: 200px

            &-data

                &-title
                    color: #333335
                    font-family: Manrope
                    font-size: 18px
                    font-style: normal
                    font-weight: 600
                    line-height: 120%

                &-list
                    color: #646468

    &-why
        display: grid
        grid-template-columns: 300px auto
        gap: 3rem

        @include max-width-md
            grid-template-columns: repeat(1, 1fr)

        &-medic

            &-img
                width: 100%
                height: 100%
                object-fit: cover
                border-radius: 8px

        &-data
            position: relative
            border-radius: 8px
            background: #E0F5FF
            padding: 2rem

            &-title
                color: #333335
                font-family: Manrope
                font-size: 18px
                font-style: normal
                font-weight: 600

            &-text
                color: #646468
                font-size: 18px

            &-medic
                color: #333335
                font-family: Manrope
                font-size: 18px
                font-style: normal
                font-weight: 600
                margin-bottom: 0

            &-position
                color: #646468

            &-arrow
                position: absolute
                top: 1rem
                left: -25px
                height: 30px

                @include max-width-md
                    display: none