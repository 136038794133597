.our-work

    &-slide
        background-color: #fff
        padding: 1.5rem
        border-radius: 8px

    &-button

        &-wrapper
            text-align: center
            // margin-top: 1.5rem

    &-slides
        position: relative
        margin-top: 2rem

    &-block
        display: grid
        grid-template-columns: 40% 60%
        gap: 2rem

        @include max-width-lg
            display: flex
            flex-direction: column

        &-image
            position: relative

            &-img
                width: 100%
                height: 290px
                border-radius: 8px
                object-fit: cover
                cursor: pointer

                @include max-width-sm
                    height: 200px

                @include min-width-lg
                    height: 260px

                @include min-width-xl
                    height: 290px

                @include min-width-xxl
                    height: 320px

            &-watermark
                position: absolute
                left: 12px
                top: 238px
                height: 40px

                &.--visible
                    display: block

                @include max-width-sm
                    top: 148px

                @include min-width-lg
                    top: 208px

                @include min-width-xl
                    top: 238px

                @include min-width-xxl
                    top: 268px

                

        &-data
            padding-right: 2rem

            &-title
                font-family: Manrope
                font-size: 18px
                font-weight: 600
                color: #333335

            &-info
                font-size: 18px
                color: #333335
                margin-bottom: 8px

                &-text
                    color: #646468

            &-time, &-medic
                font-size: 18px
                color: #333335    

            

        &-preview
            display: grid
            grid-template-columns: repeat(5, 1fr)
            gap: 0.5rem
            margin-top: 0.5rem

            @include max-width-lg
                grid-template-columns: repeat(3, 1fr)


            &-item
                width: 100%
                background-color: $bg-color
                border-radius: 4px
                animation: $animation

                @include max-width-lg
                    display: none

                &:nth-child(-n+3)
                    @include max-width-lg
                        display: block

            &-img
                width: 100%
                height: 100%
                object-fit: cover
                border-radius: 4px
                cursor: pointer






    // &-button-wrapper
    //     margin-top: 1.5rem

    // &-slides
    //     position: relative
    //     margin-top: 2rem

    // &-slide
        
    //     @include max-width-lg
    //         width: 280px !important

    //     &-img
    //         width: 100%
    //         height: 280px
    //         border-top-right-radius: 8px
    //         border-top-left-radius: 8px
    //         object-fit: cover

    //     &-data
    //         display: flex
    //         flex-direction: column
    //         justify-content: space-between
    //         height: calc(100% - 250px)
    //         padding: 16px
    //         background-color: #F5FCFF
    //         border-bottom-left-radius: 8px
    //         border-bottom-right-radius: 8px

    //         &-name
    //             color: $second-color

    //         &-treatment
    //             color: #333335
    //             margin-bottom: 8px

    //             &-block
    //                 color: #646468
    //                 font-size: 15px

    //         &-list
    //             font-size: 14px
    //             color: #646468

    //         &-time
    //             color: #333335

    //         &-doctor
    //             color: #333335
    //             margin-bottom: 0

