.slider

    &-container
        display: flex
        flex-direction: column

        &-header
            @include max-width-lg
                height: 100%

    &-main

        padding: 3rem 0 4rem
        background-image: url('../img/static/header.png'), linear-gradient(101.66deg, #FFDBF2 -10.86%, rgba(194, 220, 242, 0.25) 107.7%)
        background-repeat: no-repeat
        background-position: center right
        background-size: contain

        @include max-width-lg
            background: linear-gradient(101.66deg, #FFDBF2 -10.86%, rgba(194, 220, 242, 0.25) 107.7%)
            height: 750px

    &-white
        position: relative
        padding: 3rem 0 4rem
        background: linear-gradient(90.14deg, #FFF 45.55%, rgba(245, 252, 255, 0) 62.54%)

        @include max-width-lg
            background: linear-gradient(180deg, #FFF 44.35%, rgba(255, 255, 255, 0.00) 65.92%)
            min-height: 800px

        &-cover
            position: absolute
            right: 0
            top: 0
            height: 100%
            width: 55%
            z-index: -1

            @include max-width-lg
                // position: relative
                margin-top: 350px
                width: 100%
                height: 400px

            &-img
                height: 100%
                width: 100%
                object-fit: cover

                @include max-width-lg
                    object-position: bottom

        &-data
            // padding: 2rem 0
            display: flex
            flex-direction: column
            justify-content: space-between

            &-title
                font-family: Manrope
                color: #333335
                font-size: 32px
                font-weight: 500
                max-width: 560px
                line-height: 120%

                @include max-width-lg
                    font-size: 20px

                &.dentis
                    max-width: 410px

                &.may
                    max-width: 350px
                    margin-top: 3rem

                    @include max-width-lg
                        margin-top: 0

                & span
                    color: $primary-color

            &-subtitle
                color: #646468
                max-width: 400px

                &.full
                    max-width: max-content
                    margin-bottom: 0.5rem

            &-span
                border-radius: 8px
                padding: 8px 16px
                height: 66px
                background: linear-gradient(160deg, #4fd9f8 0%, #17afd1 100%)
                position: absolute
                top: 50%
                right: 35%
                color: $white-color

                @include max-width-xl
                    right: 30%

                @include max-width-lg
                    left: 50%
                    transform: translateX(-50%)
                    margin-top: 3rem

                &-data
                    font-weight: 400
                    font-size: 14px
                    line-height: 120%
                    text-align: center
                    color: #f8f8f8
                    margin-bottom: 0

                &-cost
                    font-weight: 600
                    font-size: 28px
                    line-height: 120%
                    text-align: right
                    color: #f8f8f8
                    margin-bottom: 0

            &-new-year
                color: rgba(46, 163, 218, 0.75)
                font-family: Sweet Mavka Script
                font-size: 28px
                font-style: normal
                font-weight: 500
                line-height: 120%

            &-wrapper
                margin: 0.5rem 0 1.5rem
                display: flex
                flex-direction: column
                gap: 1rem

            &-item
                display: flex
                align-items: center
                gap: 7px

                &-text
                    color: #646468
                    margin-bottom: 0

            &-price
                display: flex
                align-items: center
                gap: 4px
                // margin-bottom: 0.5rem

                &-name
                    color: #646468
                    font-family: Manrope
                    font-size: 20px

                &-new
                    color: #2EA3DA
                    font-family: Manrope
                    font-size: 20px

                &-old
                    color: #646468
                    text-decoration: line-through

            &-buttons
                display: flex
                gap: 1rem
                text-align: center

                @include max-width-md
                    flex-direction: column
                    align-items: center

                &-btn
                    width: 240px

                    @include max-width-lg
                        max-width: 240px

    &-standart
        display: flex !important
        align-items: center
        position: relative
        padding: 3rem 0 4rem
        background-image: linear-gradient(90.14deg, #E5F2F9 47.55%, rgba(245, 252, 255, 0) 81.54%)
        background-repeat: no-repeat
        background-position: center right
        background-size: contain

        @include max-width-lg
            padding-bottom: 0

    &-gradient
        display: flex !important
        align-items: center
        position: relative
        padding: 3rem 0 4rem
        background: linear-gradient(90.14deg, #E5F2F9 47.55%, rgba(245, 252, 255, 0) 81.54%)
        background-repeat: no-repeat
        background-position: center right
        background-size: contain

        @include max-width-lg
            padding-bottom: 0
            background: linear-gradient(180deg, #E5F2F9 46.65%, rgba(255, 255, 255, 0) 79.05%)

    &-open-day
        display: flex !important
        align-items: center
        position: relative
        padding: 3rem 0 4rem
        background: linear-gradient(99.64deg, #EBF7FF 9.3%, #F7EDFF 80.36%)
        background-repeat: no-repeat
        background-position: center right
        background-size: contain

        @include max-width-lg
            padding-bottom: 0

    &-button
        display: block
        margin-top: 0.5rem

        @include max-width-lg
            width: 100%
            text-align: center

    &-item
        height: 400px

        @include max-width-lg
            height: 580px

        @include max-width-lg
            display: flex
            flex-direction: column
            justify-content: space-between

    &-cover
        position: absolute
        right: 0
        top: 0
        height: 100%
        width: 60%
        z-index: -1

        @include max-width-lg
            height: auto

        &.top
            z-index: 1

        @include max-width-lg
            position: relative
            width: 100%
            height: 400px
            order: 2

        &-img
            height: 100%
            width: 100%
            object-fit: cover

            &.voykovskaya
                object-position: center right

            &.openday
                max-width: 780px

            @include max-width-lg
                display: none

            &-mobile
                display: none
                height: 100%
                width: 100%
                object-fit: contain
                object-position: center top
                margin-top: 2rem

                @include max-width-lg
                    position: absolute
                    bottom: 0
                    display: block
                    height: 330px
                    object-fit: cover

                &.top
                    @include max-width-lg
                        height: 100%
                        object-fit: contain
                        object-position: center bottom

    &-data
        display: flex
        flex-direction: column
        align-items: baseline
        justify-content: center
        max-width: 510px

        @include min-width-xl
            max-width: 530px

        @include max-width-lg
            max-width: 100%
            order: 1

        &-open-day
            display: flex
            flex-direction: column
            align-items: baseline
            justify-content: center
            max-width: 510px

            @include max-width-xl
                max-width: 380px

            @include max-width-lg
                max-width: 100%

        &-title
            font-family: 'Manrope', sans-serif
            font-size: 2rem
            line-height: 1.2
            font-weight: 600
            margin-bottom: 0.5rem

            @include max-width-lg
                font-size: 1.5rem

            & span
                font-size: 2.2rem
                color: #1B9BB8

                @include max-width-lg
                    font-size: 1.6rem

        &-subtitle
            font-family: 'Manrope', sans-serif
            font-size: 1.1rem
            font-weight: 600
            color: #646468

            & span
                color: #A0136C
                font-size: 1.25rem

        &-text
            font-family: 'Manrope', sans-serif
            font-size: 1.1rem
            font-weight: 600
            color: #646468
            margin-top: 0.25rem

        &-signature
            font-family: 'Manrope', sans-serif
            font-size: 1rem
            font-weight: 600
            color: #646468
