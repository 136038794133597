.handling

    &-wrapper
        display: grid
        grid-template-columns: repeat(2, 1fr)
        gap: 2rem
        margin: 2rem 0 2rem

        @include max-width-lg
            grid-template-columns: repeat(1, 1fr)

    &-item
        display: grid
        grid-template-columns: repeat(2, 1fr)
        border-radius: 8px
        box-shadow: 6px 6px 12px 0px rgba(217, 217, 217, 0.25)

        @include max-width-sm
            display: flex
            flex-direction: column

        &-img
            height: 100%
            width: 100%
            object-fit: cover
            border-radius: 8px

        &-data
            padding: 1rem

            &-title
                font-family: Manrope
                font-size: 18px
                color: #333335
                margin-bottom: 8px
                font-weight: 600

            &-text
                color: #646468
