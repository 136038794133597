.whatsapp

    &-wrapper
        display: flex
        position: absolute
        align-items: flex-end
        flex-direction: column
        bottom: 73px
        right: 40px
        z-index: 1000
        transition: all 0.3s ease

        @include max-width-xxl
            bottom: 60px

        @include max-width-xl
            bottom: 80px

        @include max-width-sm
            right: 20px
            bottom: 30px

        &.fixed
            position: fixed

    &-img
        height: 100%
        width: 100%
        cursor: pointer

        @include max-width-sm
            height: 48px

    &-block
        cursor: pointer

    &-action
        position: relative
        margin-right: 10px
        background-color: rgba(0, 0, 0, .8)
        color: white
        padding: 0.375rem 0.75rem
        border-radius: 8px
        font-size: 0.9rem

        @include max-width-xxs
            display: none

        &::after
            content: ""
            position: absolute
            display: block
            border-top: 7px solid transparent
            border-bottom: 7px solid transparent
            margin-top: -7px
            top: 50%
            border-left: 7px solid rgba(0, 0, 0, .8)
            left: auto
            right: -7px

    &-button
        display: inline-block
        background-color: transparent
        border: 1px solid transparent
        padding: 0
        height: 50px
        width: 50px
        background-color: #fff
        border-radius: 50%
        box-shadow: 0px 1px 8px 0px rgba(34, 60, 80, 0.2)

    &-menu
        display: none
        background-color: #fff
        border-radius: 8px
        box-shadow: 0px 1px 8px 0px rgba(34, 60, 80, 0.2)
        padding: 1rem 1.25rem
        margin-bottom: 0.5rem
        text-align: center
        width: 260px
        transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out

        &.--visible
            display: block

        &-header
            font-size: 1.5rem
            font-weight: 500
            line-height: 1.25

        &-text
            font-weight: 500
            line-height: 1.25
            margin-bottom: 1.25rem

        &-button
            text-decoration: none

        &-logo
            height: 50px

        &-signature
            font-size: 0.9rem
            font-weight: 600
            margin-bottom: 0
            margin-top: 10px
            color: black
