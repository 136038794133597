.airflow

    &-title
        font-weight: 600
        font-size: 32px
        line-height: 130%
        color: #333335 !important

    &-subtitle
        font-weight: 400
        font-size: 32px
        line-height: 130%
        color: #333335 !important

    &-txt
        font-weight: 400
        font-size: 20px
        line-height: 130%
        color: #646468 !important
        margin-bottom: 2rem

    &-span
        font-size: 20px
        line-height: 130%
        color: #1b9bb8
        border-radius: 6px 48px 0 6px
        width: 176px
        height: 44px
        background: #f5fcff
        display: flex
        align-items: center
        justify-content: center
        margin-bottom: 1.5rem

    &-price
        border-radius: 8px
        padding: 8px 16px
        width: 128px
        height: 66px
        background: linear-gradient(160deg, #4fd9f8 0%, #17afd1 100%)
        position: absolute
        top: 50%
        right: 35%
        color: $white-color

        @include max-width-xl
            right: 30%

        @include max-width-lg
            left: 50%
            transform: translateX(-50%)

        &-data
            font-weight: 400
            font-size: 16px
            line-height: 120%
            text-align: right
            color: #f8f8f8
            margin-bottom: 0

        &-cost
            font-weight: 600
            font-size: 24px
            line-height: 120%
            text-align: right
            color: #f8f8f8
            margin-bottom: 0
