.way

    &-wrapper
        display: flex
        gap: 2rem

        @include max-width-lg
            flex-direction: column

    &-data
        display: block
        flex-basis: calc(50% - 2rem)

        @include max-width-lg
            display: none
            flex-basis: 100%

        &-title
            font-family: Manrope
            font-size: 18px
            font-style: normal
            font-weight: 600
            color: #333335
            margin-top: 1rem

        &-list li
            color: #646468
            margin-bottom: 1rem

    &-map
        flex-basis: calc(50% - 2rem)

        @include max-width-lg
            flex-basis: 100%
            height: 400px

    &-mobile
        display: none

        @include max-width-lg
            display: block

        &-block
            display: flex
            justify-content: space-between
            align-items: center
            color: $second-color
            cursor: pointer
            margin-bottom: 1rem

            &-icon::before
                font-family: "bootstrap-icons"
                content: "\F64D"

            &[aria-expanded="true"] > .way-mobile-block-icon::before
                content: "\F63B"

            &-text
                margin-bottom: 0
