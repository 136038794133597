.top

    &-header
        position: relative
        padding: 3rem 0 4rem
        background: linear-gradient(90.14deg, #FFF 45.55%, rgba(245, 252, 255, 0) 62.54%)

        @include max-width-lg
            background: none
            padding-bottom: 0

        &-cover
            position: absolute
            right: 0
            top: 0
            height: 100%
            width: 55%
            z-index: -1

            &::before
                @include max-width-lg
                    content: ''
                    position: absolute
                    top: 0
                    left: 0
                    right: 0
                    bottom: 0
                    background: linear-gradient(180deg, #FFF 0%, rgba(245, 252, 255, 0) 15%)

            @include max-width-lg
                position: relative
                // margin-top: 350px
                width: 100%
                // height: 400px

            &-img
                height: 100%
                width: 100%
                object-fit: cover

                @include max-width-lg
                    height: auto

                &.voykovskaya
                    object-position: top center

        &-data
            display: flex
            flex-direction: column
            justify-content: space-between

            &-title
                font-family: Manrope
                color: #333335
                font-size: 32px
                font-weight: 500
                max-width: 500px
                margin-bottom: 1.5rem

                @include max-width-lg
                    font-size: 20px

            &-subtitle
                font-size: 18px
                color: #646468

            &-list
                margin: 0.5rem 0 1.5rem
                display: flex
                flex-direction: column
                gap: 1rem

                &-item
                    display: flex
                    align-items: center
                    gap: 7px

                    &-text
                        color: #646468

            &-contacts
                display: flex
                flex-direction: column
                justify-content: space-between

                &-location
                    display: flex
                    align-items: center
                    gap: 9px
                    margin-bottom: 1rem

                    &-data
                        color: #646468
                        margin-bottom: 0

                    &-img
                        height: 24px

                &-subway
                    display: flex
                    align-items: center
                    gap: 7px
                    margin-bottom: 1rem

                    &-wrapper
                        display: flex
                        gap: 1rem

                    &-img
                        height: 16px

                    &-data
                        color: #646468
                        margin-bottom: 0
                        
                &-phone
                    display: flex
                    align-items: center
                    gap: 7px
                    margin-bottom: 1.25rem

                    &-img
                        height: 16px

                    &-data
                        color: #646468
                        text-decoration: none
                        margin-bottom: 0

                        &:hover
                            color: $hover-color