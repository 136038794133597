.implantation-sale
    background: linear-gradient(101.66deg, #FFDBF2 -10.86%, rgba(194, 220, 242, 0.25) 77.7%)
    border-radius: 8px
    padding: 2rem 1.5rem

    &-wrapper
        display: flex

        @include max-width-lg
            flex-direction: column

    &-data
        flex-basis: 70%

        @include max-width-lg
            flex-basis: 100%

    &-img
        position: absolute
        top: 0
        left: 0
        height: 100%
        width: 100%
        object-fit: contain

        @include max-width-xs
            position: relative
            margin-top: 2rem
            width: 70%

        &-wrapper
            position: relative
            flex-basis: 30%

            @include max-width-lg
                flex-basis: 100%

            

    &-title
        font-family: 'Manrope', sans-serif
        font-size: 1.25rem
        font-weight: 600
        line-height: 1.2
        color: #A0136C

    &-text
        font-size: 0.9rem
        color: #333335

        & span
            font-weight: 600

    &-contacts

        &-block
            display: flex
            align-items: center
            gap: 1rem

            @include max-width-md
                flex-direction: column
                align-items: baseline

        &-subway

            &-block
                display: flex
                align-items: center
                gap: 1rem

            &-wrapper
                display: flex
                align-items: center
