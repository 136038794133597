.request
    background: linear-gradient(99.07deg, #D2E7F3 2.46%, #FEFEFE 43.93%, #D0E7F3 96.37%)

    &-label
        color: #0E6187

    &-input

        &-wrapper
            display: flex
            gap: 12px
            margin-top: 1rem

            &.horizontal
                flex-direction: column

            @include max-width-lg
                flex-direction: column

        &-item
            max-width: 280px

            @include max-width-sm
                max-width: 100%

            &.lg
                max-width: 415px

        &-button
            min-width: 200px

            @include min-width-xl
                min-width: 280px

    &-agreement

        &-wrapper
            margin-top: 1rem

        &-label
            font-size: 13px
            color: $gray-color

            & a
                font-size: 13px
                color: $gray-color

                &:hover
                    color: $acent-color

        &-checkbox
            margin-top: 5px !important

    &-consultation
        margin-top: 2rem
        display: grid
        grid-template-columns: 240px auto
        gap: 2rem

        &.head-physician
            grid-template-columns: 269px auto

        @include max-width-md
            margin-top: 1rem
            grid-template-columns: repeat(1, 1fr)

        &.offer
            grid-template-columns: calc(50% - 1rem) calc(50% - 1rem)
            margin-top: 0

            @include max-width-lg
                display: flex
                flex-direction: column

        &-subtitle
            color: $gray-color-700
            max-width: 400px

        &-title
            max-width: 580px

            &-offer
                font-size: 17px
                line-height: 24px
                color: #333335

        &-block

            &-offer
                display: flex
                flex-direction: column
                gap: 1rem
                background-color: #F5F6FF
                height: 100%
                box-shadow: 6px 6px 12px rgba(217, 217, 217, 0.25)
                border-radius: 16px
                padding: 2rem

                &-title
                    font-family: 'Manrope', sans-serif
                    font-size: 28px
                    font-weight: 600

                &-item
                    display: flex
                    gap: 1rem

                    &.center
                        align-items: center

                    &-img
                        height: 40px

                    &-data

                        &-info
                            margin-bottom: 0
                            color: #333335

                            

                            @include min-width-xl
                                font-size: 18px

                            &-form
                                margin-bottom: 0
                                color: #333335

                                @include min-width-xl
                                    font-size: 18px

                                &::before
                                    content: 'Записаться онлайн, заполнив форму справа'

                                    @include max-width-lg
                                        content: 'Записаться онлайн, заполнив форму ниже'


                        &-contacts
                            display: flex
                            gap: 1rem
                            align-items: center
                            padding-top: 1rem

                            @include max-width-lg
                                flex-direction: column
                                align-items: baseline
                                gap: 0.25rem

                        &-link
                            font-family: 'Manrope', sans-serif
                            font-size: 20px
                            font-weight: 600
                            color: #646468
                            text-decoration: none

                            &:hover
                                color: #1B86B8

                            &.m
                                margin-right: 5px

            &-request
                padding: 2rem
                background-color: #fff
                border-radius: 16px
                height: max-content

                @include max-width-md
                    background-color: #F4F4F4

                &.offer
                    background-color: #F4F4F4

            


            &-image
                
                
                @include max-width-md
                    display: none

                &.chief
                    // height: 350px

                &-name
                    font-family: 'Manrope', sans-serif
                    font-size: 0.9rem
                    line-height: 1.2
                    font-weight: 600
                    color: #333335
                    margin-bottom: 5px
                    margin-top: 1rem

                &-position
                    font-size: 0.75rem
                    color: #646468
                    margin-bottom: 0

            &-img
                // position: absolute
                width: 100%
                // height: 100%
                object-fit: cover
                border-radius: 16px

                &.chief
                    height: 70%

                