.installment
    
    &-header
        display: grid
        grid-template-columns: 60% 40%

        @include max-width-lg
            grid-template-columns: repeat(1, 1fr)

        &-data
            padding-right: 2rem

            @include max-width-lg
                margin-right: 0

        &-img
            width: 100%
            border-radius: 8px

            @include max-width-lg
                margin-top: 1.5rem

        &-text
            color: #646468