.braces-diagnostics

    &-block
        position: relative
        border-radius: 16px

        @include max-width-lg
            display: flex
            flex-direction: column

    &-data
        background: linear-gradient(90deg, #FFECF8 42.55%, #FFECF8 52.55%, rgba(255, 236, 248, 0.00) 60.54%)
        padding: 1.5rem 2rem
        border-top-left-radius: 8px
        border-bottom-left-radius: 8px

        @include max-width-lg
            background: linear-gradient(180deg, #FFECF8 42.55%, #FFECF8 87.55%, rgba(255, 236, 248, 0.00) 94.54%)
            padding-bottom: 7rem
            border-top-left-radius: 8px
            border-top-right-radius: 8px

        @include min-width-xl
            background: linear-gradient(90deg, #FFECF8 37.55%, #FFECF8 47.55%, rgba(255, 236, 248, 0.00) 55.54%)

    &-cover
        position: absolute
        right: 0
        top: 0
        width: 60%
        height: 100%
        z-index: -1
        

        @include max-width-lg
            position: relative
            width: 100%
            margin-top: -7rem
            height: 450px
            

        &-img
            width: 100%
            height: 100%
            object-fit: cover
            border-bottom-right-radius: 8px
            border-top-right-radius: 8px

            @include max-width-lg
                border-bottom-right-radius: 8px
                border-bottom-left-radius: 8px

    &-title
        font-size: 1.5rem
        font-weight: 600
        font-family: Manrope
        color: #17AFD1

    &-text
        font-size: 1.1rem
        color: #646468
        margin-bottom: 0
        max-width: 450px

        @include min-width-xxl
            font-size: 1.15rem
            max-width: 580px

        & span
            font-weight: 600

    &-free

        &-block
            padding: 8px 24px
            background-color: #fff
            border-radius: 8px
            display: grid
            grid-template-columns: 110px 230px
            align-items: center
            gap: 1rem
            margin-top: 1.5rem
            width: max-content

            @include max-width-sm
                width: 100%
                display: flex
                justify-content: space-around

            &-price
                display: flex
                align-items: center
                gap: 1rem

                @include max-width-sm
                    flex-direction: column
                    gap: 0

        &-price
            color: #AD4682
            font-family: Manrope
            font-size: 2.5rem
            font-weight: 700

            @include max-width-sm
                font-size: x-large

        &-old
            color: #646468
            font-size: 1.75rem
            text-decoration-line: line-through

            @include max-width-sm
                font-size: 22px

        &-text
            color: #646468
            margin-bottom: 0
            font-size: 18px
            line-height: 120%

            @include max-width-sm
                flex: 0

    &-buttons
        display: flex
        align-items: center
        gap: 1rem
        margin-top: 1.5rem

        @include max-width-sm
            flex-direction: column
    
    &-button
        @include max-width-sm
            display: block
            margin: auto
            width: 100%

    &-validity
        font-size: 14px
        color: #646468
        margin-bottom: 0
        margin-top: 1rem

        @include max-width-lg
            display: none

    &-label
        position: absolute
        top: 0
        left: 50%
        transform: translateX(-30%)
        border-bottom-right-radius: 8px
        border-bottom-left-radius: 8px
        color: #fff
        background-color: #A0136C
        padding: 4px 12px

        @include max-width-lg
            top: initial
            bottom: -16px
            transform: translateX(-50%)
            // transform: translateY(50%)
            width: max-content
            border-radius: 8px

    &-info
        display: flex
        gap: 8px
        max-width: 450px

        @include min-width-xxl
            max-width: 550px

        &-icon
            width: 32px
            height: 32px

        &-text
            font-size: 18px
            margin-bottom: 0

            & span
                color: #A0136C