.nav

    &-menu
        overflow: hidden
        position: sticky
        top: 0px
        background-color: transparent
        z-index: 999
        padding-bottom: 1rem
        margin-bottom: -1rem
        pointer-events: none

        @include max-width-lg
            display: none

        &-section
            box-shadow: 0 .125rem .125rem -0.125rem rgba(31,27,45,.08),0 .25rem .75rem rgba(31,27,45,.08)
            padding: 1rem 0
            background-color: #fff
            pointer-events: all

        &-block
            display: flex
            justify-content: space-between
            gap: 2.5rem

        &-link
            font-weight: 600
            color: #8E8F94
            text-decoration: none

            &:hover
                color: #2798CD

        &-search
            color: #8E8F94
            background-color: transparent
            border-color: transparent
            font-size: 1rem
            border: none
            padding: 0
            cursor: pointer
            // margin-left: auto
            margin-top: -4px

            & img
                height: 20px