.insurance

    &-header
        display: grid
        grid-template-columns: 60% 40%

        @include max-width-lg
            grid-template-columns: repeat(1, 1fr)

        &-data
            padding-right: 2rem

            @include max-width-lg
                margin-right: 0

        &-img
            width: 100%
            border-radius: 8px

            @include max-width-lg
                margin-top: 1.5rem

        &-text
            color: #646468

    &-wrapper
        margin-top: 2rem

    &-list
        columns: 2

        @include max-width-md
            columns: 1

        & li
            color: #646468

    &-info

        &-block
            display: flex
            align-items: center
            gap: 2rem

            @include max-width-lg
                flex-direction: column

        &-data
            display: block
            flex-basis: calc(50% - 2rem)

            &-title
                font-family: 'Manrope', sans-serif
                font-size: 18px
                font-weight: 600
                color: #333335

            &-text
                color: #646468

        &-img
            width: calc(50% - 2rem)
            height: 100%
            object-fit: cover
            border-radius: 8px

            @include max-width-lg
                width: 100%

            @include min-width-xxl
                width: 40%
    
    &-slide


        & img
            display: block
            width: 100%
            height: 100%
            object-fit: contain