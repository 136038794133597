.braces-steps

    &-wrapper
        display: grid
        grid-template-columns: 50% 50%
        align-items: center
        gap: 2rem

        @include max-width-xl
            grid-template-columns: 40% 60%

        @include max-width-lg
            display: block

    &-img
        width: 100%

        &-block
            @include max-width-lg
                display: none

    &-title
        color: #17AFD1
        font-family: Manrope
        font-size: 1.5rem
        font-weight: 600

    &-block
        display: flex
        justify-content: space-between
        align-items: center
        cursor: pointer
        border-bottom: 1px solid #646468
        padding: 1.25rem 0 0.75rem
        transition: 0.3s

        &[aria-expanded="true"] > .braces-steps-block-icon
            transform: rotate(45deg)
            

        &-text
            font-family: 'Manrope', sans-serif
            font-size: 1.2rem
            color: #333335
            margin-bottom: 0

        &-icon
            display: flex
            align-items: center
            justify-content: center
            width: 24px
            height: 24px
            border-radius: 50%
            background-color: #fff

        &-img
            width: 16px
            height: 16px

    &-collapse

        &-text
            margin: 1rem 0 2rem
            color: #646468
            margin-bottom: 0