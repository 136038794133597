.rating

    &-section
        position: relative

    &-title
        font-family: 'Manrope', sans-serif
        font-size: 28px
        font-weight: 500
        line-height: 1.2
        color: #333335
        margin-top: 1rem
        margin-right: 5rem

        @include min-width-xl
            margin-right: 8rem

        @include max-width-lg
            margin-right: 0

        & span
            color: #1B9BB8
            font-size: 1.6rem

    &-wrapper
        display: flex
        align-items: center
        gap: 1rem

        @include max-width-lg
            flex-direction: column
            align-items: baseline

        &-review
            display: flex
            gap: 1rem

            @include max-width-xs
                width: 100%
                flex-direction: column

    &-block
        display: flex
        gap: 1rem
        border: 1px solid #E0F5FF
        box-shadow: 6px 6px 12px rgba(217, 217, 217, 0.25)
        border-radius: 8px
        padding: 1rem
        background-color: #fff

        @include max-width-xs
            width: 100%
            justify-content: space-around

    &-item
        text-align: center

        &-review
            font-family: Manrope
            font-size: 2.2rem
            font-weight: 500
            line-height: 1
            margin-bottom: 0
            color: #333335

        &-possible
            font-size: 0.9rem
            color: #84858A
            margin-bottom: 0

        &-company
            display: flex
            flex-direction: column
            gap: 8px

        &-logo
            height: 28px

        &-stars
            height: 20px

    &-line
        position: absolute
        border: 4px solid #E0F5FF
        right: 0
        top: calc(50% - 4px)
        width: 50%
        z-index: -1

        @include max-width-lg
            display: none