.implantation

    &-form-title
        font-family: 'Manrope', sans-serif
        font-size: 1.25rem
        line-height: 1.2
        font-weight: 600
        color: #333335
        max-width: 430px !important

    &-experience
        color: #646468
        margin-top: 1rem

    &-offer-text
        font-family: 'Manrope', sans-serif
        font-size: 1.2rem
        font-weight: 600
        color: #A0136C

    &-modal
        padding: 1rem
        border-radius: 16px !important
        border: 0 !important

        &-close
            position: absolute
            top: 12px
            right: 12px

        &-content

            &-img
                width: 100%
                margin-top: 2rem

            &-text
                color: #646468
                margin-top: 1rem

            &-medic
                display: flex
                align-items: center
                gap: 1rem

                &-img
                    flex: none
                    height: 48px
                    width: 48px
                    object-fit: cover
                    object-position: center top
                    border-radius: 50%

                &-position
                    font-size: 14px
                    color: #8E8F94
                    margin-bottom: 0

    &-offer
        font-family: 'Manrope', sans-serif
        font-size: 1.25rem
        font-weight: 500
        color: $second-color

    &-advantages

        &-wrapper
            display: flex
            flex-wrap: wrap
            gap: 1rem
            margin-top: 2rem

        &-item
            display: flex
            align-items: center
            gap: 1rem
            padding: 1rem
            flex-basis: calc(33.333333% - 1rem)
            // margin-bottom: 1.5rem
            background-color: #F5FEFF
            border: 1px solid #70E5F2
            border-radius: 8px

            @include max-width-lg
                flex-basis: calc(50% - 1rem)

            @include max-width-sm
                flex-basis: 100%

            &-img
                position: absolute
                height: 20px
                width: 20px

                &-block
                    display: flex
                    flex: none
                    align-items: center
                    justify-content: center
                    position: relative
                    background-color: #27BCCD
                    border-radius: 50%
                    width: 40px
                    height: 40px

            &-text
                font-size: 14px
                color: #646468
                margin-bottom: 0

    &-kind

        &-installment

            &-wrapper
                display: flex
                justify-content: space-between
                flex-wrap: wrap
                gap: 1rem
                margin-top: 1rem

                @include max-width-lg
                    display: none

            &-item
                background: #C5F7FD
                box-shadow: 6px 6px 12px rgba(217, 217, 217, 0.25)
                border-radius: 8px
                padding: 1rem
                text-align: center
                flex-basis: calc(33.333333% - 1rem)

                &-text
                    margin-bottom: 0
                    font-size: 0.8rem

                    @include min-width-xl   
                        font-size: 0.95rem

                    @include min-width-xxl   
                        font-size: 1rem

                &-info
                    font-weight: 600
                    margin-bottom: 0

                    & span
                        color: #A0136C


        &-wrapper
            display: flex
            justify-content: space-between
            flex-wrap: wrap
            gap: 1rem

            @include max-width-lg
                justify-content: center

        &-item
            display: flex
            flex-direction: column
            background: #FFFFFF
            box-shadow: 6px 6px 12px rgba(217, 217, 217, 0.25)
            border-radius: 8px
            flex-basis: calc(33.333333% - 1rem)
            overflow: hidden

            @include max-width-lg
                flex-basis: 51%

            @include max-width-sm
                flex-basis: 100%

            &-img
                order: 1
                width: 100%

                @include max-width-lg
                    order: 2

        &-header
            display: flex
            justify-content: space-between
            padding: 1rem

            &-title
                font-family: 'Manrope', sans-serif
                font-size: 1.1rem
                font-weight: 600
                color: #333335
                margin-bottom: 0

            &-subtitle
                font-size: 14px
                color: #8E8F94
                margin-bottom: 0

            &-img
                height: 24px

        &-price

            &-block
                display: flex
                justify-content: space-between
                align-items: center
                gap: 1rem
                padding: 1rem
                background-color: #C5F7FD
                order: 2

                @include max-width-lg
                    order: 1

            &-text
                font-size: 14px
                color: #333335
                margin-bottom: 0

                @include max-width-sm
                    max-width: 140px

            &-data
                flex: none
                text-align: right

                &-cost
                    font-family: 'Manrope', sans-serif
                    font-size: 1.2rem
                    font-weight: 600
                    color: #333335
                    margin-bottom: 0
                    line-height: 1.2

                &-sale
                    font-size: 13px
                    color: #8E8F94
                    margin-bottom: 0
                    text-decoration: line-through

                    &-block
                        display: flex
                        align-items: center
                        gap: 4px

                    &-offer
                        background: url('../img/static/offer-line.svg') no-repeat center center
                        color: #fff
                        font-size: 11px
                        width: 16px
                        height: 16px
                        padding-right: 3px

        &-offer

            &-title
                color: $second-color
                margin-bottom: 0
                font-family: 'Manrope', sans-serif
                font-weight: 500

                &-block
                    display: none

                    @include max-width-lg
                        display: block
                        flex-basis: 51%

                    @include max-width-sm
                        flex-basis: 100%

            &-block
                padding: 1rem
                order: 3

                @include max-width-lg
                    display: none

            &-mobile

                &-block
                    display: none
                    padding: 1rem
                    background-color: #fff
                    box-shadow: 6px 6px 12px rgba(217, 217, 217, 0.25)
                    border-radius: 8px

                    @include max-width-lg
                        display: block
                        flex-basis: 51%

                    @include max-width-sm
                        flex-basis: 100%

            &-item
                display: flex
                justify-content: space-between
                align-items: center
                gap: 1rem
                border-bottom: 1px solid #E8E9F2
                padding: 0.5rem 0

                &:first-child
                    padding-top: 0

            &-button
                margin-top: 1rem
                width: 100%

            &-text
                font-size: 14px
                color: #646468
                margin-bottom: 0

            &-sale
                font-size: 14px
                color: $second-color
                margin-bottom: 0
                text-decoration: underline

            &-span
                padding: 2px 8px
                border-radius: 4px
                color: #FFFFFF
                background-color: $second-color

            &-clause
                font-size: 14px
                color: $second-color
                margin-bottom: 0

            &-img
                height: 18px
                width: 18px

        &-validity

            &-block
                flex-basis: 100%
                margin-top: 1rem

                @include max-width-lg
                    display: block
                    flex-basis: 51%

                @include max-width-sm
                    flex-basis: 100%

            &-text
                font-size: 12px
                color: #8E8F94
                margin-bottom: 0

    // &-why
    //     background: linear-gradient(120.57deg, #FEFEFE 46.35%, #E5F2F9 75%, #B6D4F0 100%)

    //     &-header
    //         display: grid
    //         grid-template-columns: 32px auto 250px
    //         gap: 1rem

    //         @include max-width-md
    //             display: block

    //     &-quote
    //         width: 32px
    //         margin-top: 6px

    //         @include max-width-md
    //             display: none

    //         &-img
    //             width: 100%
    //             opacity: 0.7

    //     &-data
    //         display: block

    //         &-title
    //             font-size: 1.75rem
    //             line-height: 1.25
    //             color: $second-color
    //             margin-bottom: 8px

    //             @include min-width-xl
    //                 margin-bottom: 2rem

    //             @include max-width-lg
    //                 font-size: 1rem
    //                 margin-bottom: 2rem

    //             @include max-width-md
    //                 display: none

    //         &-text
    //             color: $gray-color-700

    //             @include max-width-lg
    //                 margin-bottom: 2rem

    //             @include min-width-xl
    //                 margin-bottom: 2rem

    //     &-img
    //         width: 100%
    //         border-radius: 8px

    //         &-block
    //             @include max-width-md
    //                 display: none

    //         &-position
    //             font-size: 12px
    //             color: #8E8F94
    //             margin-bottom: 0
    //             margin-top: 8px

    //     &-buttons
    //         display: flex
    //         align-items: center
    //         gap: 1rem

    //         @include max-width-lg
    //             flex-direction: column

    //     &-mobile

    //         &-block
    //             gap: 1rem
    //             align-items: flex-start
    //             display: none
    //             margin-bottom: 2rem

    //             @include max-width-md
    //                 display: flex

    //             &-title
    //                 line-height: 1.25
    //                 color: $second-color
    //                 margin-bottom: 0
    //                 max-width: 160px

    //             &-img
    //                 width: 32px
    //                 opacity: 0.7
    //                 margin-top: 4px

    //             &-info
    //                 position: relative

    //                 &-img
    //                     position: absolute
    //                     bottom: 0
    //                     right: -1.75rem
    //                     height: 22px

    //             &-link
    //                 text-decoration: none

    // &-crown

    //     &-header
    //         font-family: 'Manrope', sans-serif
    //         font-size: 1.25rem
    //         font-weight: 500
    //         color: $second-color
    //         max-width: 500px

    //     &-wrapper
    //         display: flex
    //         justify-content: space-between
    //         gap: 1rem

    //         @include max-width-lg
    //             width: max-content

    //         &-content
    //             overflow-y: scroll
    //             scrollbar-width: none
    //             -ms-overflow-style: none

    //             &::-webkit-scrollbar
    //                 display: none
    //                 width: 0
    //                 height: 0

    //     &-item
    //         position: relative
    //         flex-basis: calc(33.333333% - 1rem)
    //         background: #F5FEFF
    //         border: 1px solid #27AECD
    //         box-shadow: 6px 6px 12px rgba(217, 217, 217, 0.25)
    //         border-radius: 8px
    //         padding: 1.5rem

    //         @include max-width-lg
    //             width: 280px
    //             flex-basis: 280px

    //     &-title
    //         font-family: 'Manrope', sans-serif
    //         font-size: 1.1rem
    //         font-weight: 500
    //         color: $second-color

    //     &-price

    //         &-block
    //             margin: 1rem 0

    //         &-cost
    //             font-family: 'Manrope', sans-serif
    //             font-size: 1.2rem
    //             font-weight: 600
    //             color: #333335
    //             margin-bottom: 0

    //         &-benefit
    //             font-size: 13px
    //             color: #8E8F94
    //             margin-bottom: 0

    //     &-list

    //         & li
    //             font-size: 14px
    //             color: #646468
    //             margin-bottom: 1rem

    //             &:last-child
    //                 margin-bottom: 4rem

    //     &-button
    //         position: absolute
    //         bottom: 1.5rem
    //         width: calc(100% - 3rem)
    //         margin-top: 0.375rem

    &-warning

        &-title
            font-family: 'Manrope', sans-serif
            font-size: 1.2rem
            font-weight: 500
            color: #333335

        &-wrapper
            display: flex
            gap: 1rem

            @include max-width-md
                flex-direction: column

        &-item
            display: flex
            gap: 0.5rem
            background: #FFFFFF
            border: 1px solid #C9CAD1
            border-radius: 8px
            padding: 1rem

            &-img
                height: 18px
                width: 18px
                margin-top: 4px

            &-text
                font-size: 15px
                color: #646468
                margin-bottom: 0

                & span
                    text-decoration: underline

    &-step

        &-helper
            color: $second-color
            font-size: 15px

        &-wrapper
            display: flex
            flex-wrap: wrap
            justify-content: space-between
            gap: 1rem
            margin-top: 0.5rem

            @include max-width-lg
                width: max-content

            &-content
                overflow-y: scroll
                scrollbar-width: none
                -ms-overflow-style: none

                &::-webkit-scrollbar
                    display: none
                    width: 0
                    height: 0

        &-item
            position: relative
            flex-basis: calc(50% - 1rem)
            background: #F5FEFF
            border: 1px solid #27AECD
            box-shadow: 6px 6px 12px rgba(217, 217, 217, 0.25)
            border-radius: 8px
            padding: 1.25rem

            @include max-width-lg
                width: 230px
                flex-basis: 230px

            &-header
                display: flex
                align-items: center
                gap: 1rem

            &-img
                height: 60px
                width: 60px
                margin-bottom: 0.75rem

            &-step
                font-size: 15px
                color: $second-color
                margin-bottom: 5px

            &-action
                font-family: 'Manrope', sans-serif
                font-weight: 600
                color: $second-color

            &-text
                font-size: 14px
                color: #646468
                margin-bottom: 0

    &-anesthesia

        &-block
            border: 1px solid #AFB0B6
            border-radius: 8px
            padding: 1rem
            max-width: calc(50%)
            margin-top: 2rem

            @include max-width-lg
                max-width: 100%

        &-text
            font-size: 14px
            color: #AFB0B6
            margin-bottom: 0
            margin-top: 2rem

    &-solution
        display: grid
        grid-template-columns: 270px auto
        gap: 2rem

        @include max-width-lg
            display: block

        &-img
            width: 100%
            border-radius: 8px

            @include max-width-lg
                width: 50%

            &-block
                display: block

                @include max-width-lg
                    display: flex
                    align-items: flex-end
                    gap: 1rem
                    margin-bottom: 1rem

        &-name
            font-family: 'Manrope', sans-serif
            font-size: 1.1rem
            line-height: 1.2
            font-weight: 600
            margin-bottom: 5px
            margin-top: 1rem

            @include max-width-lg
                font-size: 1.1rem

        &-position
            font-size: 14px
            color: #8E8F94
            margin-bottom: 0

            @include max-width-lg
                flex-basis: calc(50% - 1rem)

        &-data

            &-title
                font-family: 'Manrope', sans-serif
                font-size: 1.5rem
                font-weight: 500
                color: $second-color
                max-width: 500px

                @include max-width-lg
                    display: none

                &-mobile
                    display: none
                    font-family: 'Manrope', sans-serif
                    font-size: 1.2rem
                    font-weight: 500
                    color: $second-color

                    @include max-width-lg
                        display: block

            &-text
                color: #646468
                max-width: 700px

                & span
                    font-weight: 600

            &-check
                font-size: 15px
                color: #646468
                margin-bottom: 0.5rem

                & i
                    color: $second-color
                    margin-right: 4px
                    font-size: 16px

                &-title
                    color: #333335

            &-final
                color: #333335
                margin: 1rem 0

        &-button
            @include max-width-lg
                display: block
                text-align: center

            &-wrapper
                margin-top: 1.5rem
