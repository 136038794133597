.braces-header
    padding: 3rem 0 4rem
    background-image: url('../img/static/header-braces.png'), linear-gradient(135deg, #FFDBF2 0%, rgba(194, 220, 242, 0.25) 100%)
    background-repeat: no-repeat
    background-position: center right
    background-size: contain

    @include max-width-lg
        background: linear-gradient(135deg, #FFDBF2 0%, rgba(194, 242, 233, 0.25) 100%)
        padding-bottom: 0

    &-data
        width: 470px

        @include max-width-lg
            width: auto
            display: flex
            flex-direction: column
            height: 100%

    &-title
        font-family: Manrope
        font-size: 2.3rem
        font-weight: 600
        max-width: 500px

        & span.blue
            color: #1B9BB8
            font-size: 2.4rem

        & span.pink
            color: #A0136C
            font-size: 2.4rem

    &-subtitle
        color: $gray-color-700
        font-size: 1.2rem
        margin-top: 1.5rem
        font-weight: 600

        @include max-width-lg
            margin-top: 0.75rem
            margin-bottom: 1rem

    &-image
        display: none
        margin: auto
        width: 100%

        @include max-width-lg
            display: block

    &-button
        margin-top: 2rem

        @include max-width-lg
            display: block
            margin: auto
            width: 100%
            margin-top: 2rem

    &-advantages
        display: flex
        flex-direction: column
        gap: 0.5rem
        margin: 2rem 0 2rem

        &-item
            display: flex
            align-items: center
            gap: 0.375rem

            &-text
                color: #646468
                font-family: Source Sans Pro
                font-size: 1.1rem
                margin-bottom: 0

                @include max-width-lg
                    font-size: 1rem

                & span
                    font-weight: 600

    &-installment
        position: relative
        border-radius: 5px
        background: rgba(255, 255, 255, 0.80)
        box-shadow: 6px 6px 12px 0px rgba(217, 217, 217, 0.25)
        width: 300px

        @include max-width-lg
            width: 100%

        &-header
            position: absolute
            top: 0
            left: 0
            background-color: #A0136C
            font-size: 18px
            font-weight: 600
            padding: 0rem 1rem 0.15rem
            width: 100%
            border-radius: 5px
            color: #fff

        &-data
            padding: 2.5rem 1rem 1rem
            
        &-text
            color: #646468
            max-width: 220px
            margin-bottom: 4px

            

            & span
                font-weight: 600

        &-price
            color: #AD4682
            font-weight: 600
            margin-bottom: 0

        &-zero
            position: absolute
            height: 130px
            right: -100px
            top: 0
            z-index: 1

            @include max-width-lg
                display: none