.implantation-cause

    &-title
        font-family: 'Manrope', sans-serif
        font-size: 1.75rem
        font-weight: 600
        line-height: 1.2
        color: #333335
        margin-top: 1rem

        @include min-width-xxl
            font-size: 1.9rem

        & span
            color: #1B9BB8
            font-size: 1.85rem

            @include min-width-xxl
                font-size: 2rem

    &-wrapper
        display: flex
        align-items: center
        flex-wrap: wrap
        gap: 2rem
        margin-top: 2rem

    &-item
        display: flex
        align-items: center
        gap: 1rem
        flex-basis: calc(50% - 2rem)

        @include max-width-lg
            flex-basis: 100%
            border-bottom: 1px solid #DBDCE4
            padding-bottom: 2rem

        &-number
            font-family: 'Manrope', sans-serif
            font-size: 4rem
            font-weight: 600
            margin-bottom: 0
            color: #A0136C

        &-data

            &-title
                font-family: 'Manrope', sans-serif
                font-size: 1.2rem
                font-weight: 600
                line-height: 1.2
                margin-bottom: 4px
                color: #A0136C

                @include min-width-xxl
                    font-size: 1.3rem

            &-text
                font-size: 0.9rem
                color: #646468
                margin-bottom: 0
                max-width: 320px

                @include min-width-xxl
                    font-size: 1.1rem

                & span
                    font-weight: 600
