.implantation-after

    &-wrapper
        display: flex
        gap: 1rem
        margin-top: 1rem

        @include max-width-lg
            flex-direction: column

    &-item
        flex-basis: 33.33333%

        @include max-width-lg
            flex-basis: 100%

        &-info
            display: flex
            flex-direction: column
            align-items: flex-start
            padding: 24px 16px
            gap: 1.5rem
            height: 100%
            justify-content: space-between
            background-color: #F4F4F4
            border-radius: 8px

            &-item
                display: flex
                flex-direction: row
                align-items: center
                padding: 0px
                gap: 16px

                &-img
                    height: 50px

                &-text
                    font-family: 'Manrope', sans-serif
                    // font-size: 1.1rem
                    font-weight: 600
                    color: #646468
                    margin-bottom: 0

        &-img
            position: absolute
            top: 0
            left: 0
            width: 100%
            height: 100%
            object-fit: cover
            border-radius: 8px

            &-wrapper
                position: relative
                width: 100%
                height: 100%

                @include max-width-lg
                    height: 350px

        &-wrapper
            display: flex
            flex-direction: column
            gap: 1rem

        &-block
            flex-basis: 50%

            &-review
                border: 1px solid #C5F7FD
                background-color: #fff
                box-shadow: 6px 6px 12px rgba(217, 217, 217, 0.25)
                border-radius: 8px
                padding: 1rem

                &-header
                    display: flex
                    align-items: center
                    justify-content: space-between
                    margin-bottom: 0.75rem

                    &-date
                        font-size: 12px
                        color: #8E8F94
                        margin-bottom: 0

                    &-star
                        display: flex
                        align-items: center

                        &-text
                            flex: none
                            font-size: 12px 
                            margin-bottom: 0
                            margin-right: 5px

                        &-img
                            height: 14px

                &-text
                    font-size: 12px
                    color: #646468

                    @include min-width-xl
                        font-size: 14px

                &-signature
                    font-size: 14px
                    color: #A0136C
                    margin-bottom: 0

            &-osstem
                background: linear-gradient(101.66deg, #FFDBF2 -10.86%, rgba(194, 220, 242, 0.25) 107.7%)
                border-radius: 8px
                padding: 1rem

                &-wrapper
                    display: flex
                    justify-content: space-between

                    @include max-width-xs
                        flex-direction: column

                &-title
                    font-family: 'Manrope', sans-serif
                    font-size: 1.25rem
                    font-weight: 600
                    color: #A0136C

                &-text
                    font-family: 'Manrope', sans-serif
                    font-size: 1.25rem
                    font-weight: 600
                    line-height: 1.2
                    margin-bottom: 0
                    color: #646468

                &-subtitle
                    font-size: 14px
                    color: #8E8F94

                &-img
                    width: 35%
                    object-fit: contain

                    @include max-width-lg
                        width: 20%

                    @include max-width-xs
                        width: 70%
                        padding: 1rem
                        display: block
                        margin: auto

                &-button
                    font-size: 0.9rem

                    @include max-width-xs   
                        width: 100%