.hello
    background: linear-gradient(120.57deg, #FEFEFE 46.35%, #E5F2F9 75%, #B6D4F0 100%)
    // background: linear-gradient(76.81deg, #B6D4F0 0.99%, #E5F2F9 20.26%, #FEFEFE 52.01%)

    &-block
        display: flex

        @include max-width-lg
            display: flex
            flex-direction: column

        &-chief

            &-img
                width: 250px

                @include max-width-sm
                    width: 50%

            &-signature
                font-size: 12px
                color: $gray-color
                margin-top: 8px
                margin-bottom: 0

                @include max-width-lg
                    margin-left: 1rem

            &-view
                display: block

                @include max-width-lg
                    display: flex
                    flex: 50 50
                    order: 2
                    align-items: flex-end

        &-quote
            display: flex
            align-items: start
            margin-left: 32px

            @include max-width-lg
                order: 1
                margin-left: 0
                margin-bottom: 1rem

            &-img
                width: 35px
                margin-right: 16px
                margin-top: 8px
                opacity: 0.5


            &-title
                font-size: 1.75rem
                line-height: 1.25
                color: $second-color
                margin-bottom: 8px

                @include max-width-lg
                    font-size: 1rem

            &-text
                color: $gray-color-700 

                @include max-width-lg
                    display: none

            &-button
                @include max-width-lg
                    display: none

            &-mobile

                &-text
                    font-size: 14px
                    color: $gray-color-700 
                    margin-top: 1rem
                    display: none

                    @include max-width-lg
                        display: block

                &-button
                    display: none

                    @include max-width-lg
                        display: block