.whats

    &-wrapper
        display: grid
        grid-template-columns: calc(50% - 1rem) calc(50% - 1rem)
        gap: 2rem
        // align-items: center

        @include max-width-lg
            grid-template-columns: repeat(1, 1fr)

    &-item

        &-img
            width: 100%

        &-info

            &-title
                font-family: Manrope
                font-size: 32px
                font-style: normal
                font-weight: 500
                line-height: 120%
                color: #333335

            &-block
                background-color: #F0F2FA
                padding: 24px 32px
                box-shadow: 6px 6px 12px 0px rgba(217, 217, 217, 0.25)
                color: #333335
                font-size: 18px
                margin-bottom: 1.5rem

            &-text
                color: #646468

                & span
                    font-weight: 700

            &-data
                color: #1B86B8
                font-family: Manrope
                font-size: 18px
                font-style: normal
                font-weight: 600

            &-button
                @include max-width-xs
                    width: 100%