.mass-media

    &-header
        position: relative
        padding: 3rem 0 4rem
        background: linear-gradient(90.14deg, #F5F5F5 47.55%, rgba(245, 252, 255, 0) 81.54%)

        @include max-width-lg
            background: linear-gradient(180deg, #FFF 44.35%, rgba(255, 255, 255, 0.00) 65.92%)
            min-height: 800px

        &-cover
            position: absolute
            right: 0
            top: 0
            height: 100%
            width: 60%
            z-index: -1

            @include max-width-lg
                // position: relative
                margin-top: 350px
                width: 100%
                height: 400px

            &-img
                height: 100%
                width: 100%
                object-fit: cover

        &-data
            min-height: 250px
            padding: 2rem 0

            &-title
                font-size: 42px
                color: #333335

            &-text
                font-size: 20px
                color: #646468
                max-width: 590px

    &-wrapper
        display: flex
        flex-wrap: wrap
        gap: 1rem
        margin-top: 2rem

    &-show-more
        display: none
        margin-top: 2rem
        text-align: center

        &.--visible
            display: block

    &-data

        &-item
            width: calc(33.333333% - 1rem)
            flex-basis: calc(33.333333% - 1rem)
            display: flex
            flex-direction: column
            gap: 1rem
            box-shadow: 6px 6px 12px 0px rgba(217, 217, 217, 0.25)
            padding: 16px
            text-decoration: none
            align-items: baseline

            @include max-width-lg
                width: calc(50% - 1rem)
                flex-basis: calc(50% - 1rem)

            @include max-width-sm
                width: 100%
                flex-basis: 100%

            @include min-width-xxl
                width: calc(25% - 1rem)
                flex-basis: calc(25% - 1rem)

            &:hover
                color: $primary-color

            &-img
                height: 56px
                object-fit: cover

                &.empty
                    object-fit: fill

            &-title
                font-weight: 600
                margin-bottom: 0

    &-slides
        margin-top: 2rem

        &-data

            &-item
                display: flex
                flex-direction: column
                gap: 1rem
                // box-shadow: 3px 3px 12px -3px rgba(217, 217, 217, 0.25)
                padding: 16px
                text-decoration: none
                align-items: baseline
                height: 100%

                &-content
                    display: flex

    &-source
        display: flex
        justify-content: flex-end
        gap: 4px

        &-name
            color: #646468

        &-link
            color: #1373A0
            text-decoration: none

            &:hover
                color: darken($primary-color, 10%)