.braces-price

    &-wrapper
        display: grid
        grid-template-columns: calc(50% - 1rem) calc(50% - 1rem)
        gap: 2rem
        margin-top: 2rem

        @include max-width-lg
            display: flex
            flex-direction: column

    &-item
        background-color: #E8E9F2
        border-radius: 16px

        &-img
            height: 300px
            width: 100%
            object-fit: cover
            border-top-right-radius: 8px
            border-top-left-radius: 8px

        &-data
            padding: 1.5rem

        &-title
            font-family: Manrope
            font-size: 1.4rem
            font-weight: 600
            color: #333335
            margin-bottom: 0.5rem

            & span
                color: #AD4682

        &-info
            color: #646468

    &-info
        display: grid
        grid-template-columns: 160px 160px auto
        gap: 1rem
        align-items: center
        margin-left: -1.5rem
        background-color: #fff
        padding: 8px 1rem 8px 1.5rem

        

        @include min-width-xxl
            grid-template-columns: repeat(3, 1fr)

        @include max-width-xl
            grid-template-columns: 128px 154px auto

        @include max-width-sm
            grid-template-columns: 130px auto auto
            gap: 0.5rem
            

        &-item
            
            &.inspection
                @include max-width-sm
                    display: flex
                    gap: 0.5rem
                    align-items: center
                    grid-column: span 2
                    border-top: 1px solid $separator-color
                    padding-top: 0.5rem

            &-price
                font-size: 2.4rem
                font-weight: 700
                color: #17AFD1
                margin-bottom: 0

                @include max-width-xl
                    font-size: 1.9rem

            &-condition
                font-size: 1.1rem
                margin-bottom: 0
                line-height: 120%

                @include max-width-xl
                    font-size: 1rem

                & span
                    font-weight: 700

            &-extra
                color: #17AFD1
                font-size: 18px
                font-style: normal
                font-weight: 700
                margin-bottom: 0

            &-note
                font-size: 14px
                color: #646468
                margin-bottom: 0

    &-advantages
        display: flex
        flex-direction: column
        gap: 0.5rem
        margin: 1.5rem 0 0

        &-item
            display: flex
            align-items: center
            gap: 0.375rem

            &-text
                color: #646468
                font-family: Source Sans Pro
                margin-bottom: 0

    &-entry
        display: flex
        align-items: center
        justify-content: space-between
        align-items: flex-end

        @include max-width-xl
            flex-direction: column
            gap: 1rem
            margin-top: 1rem
            align-items: flex-start

        &-text
            color: #646468
            font-size: 14px
            margin-bottom: 0

    &-button
        @include max-width-sm
            display: block
            margin: auto
            width: 100%