.jobs

    &-card
        height: 250px

        

        // @include min-width-xl
        //     height: 320px

        // @include min-width-xxl
        //     height: 270px

        // @include max-width-sm
        //     height: 470px

        // @include max-width-variable(494px)
        //     height: 420px

        // @include max-width-variable(414px)
        //     height: 320px

        // @include max-width-variable(320px)
        //     height: 250px

        

    &-slide
        height: 250px !important

        // @include min-width-xl
        //     height: 320px

        // @include min-width-xxl
        //     height: 270px

        // @include max-width-sm
        //     height: 470px

        // @include max-width-variable(494px)
        //     height: 420px

        // @include max-width-variable(414px)
        //     height: 320px

        // @include max-width-variable(320px)
        //     height: 250px
        
    &-wrapper
        display: flex
        flex-wrap: wrap
        gap: 2rem
        margin-top: 2rem

    &-item
        display: flex
        flex-direction: column
        width: calc(50% - 2rem)
        flex-basis: calc(50% - 2rem)
        // margin-bottom: 1.5rem

        @include max-width-lg
            width: 100%
            flex-basis: 100%

        // @include max-width-sm
        //     width: 100%
        //     flex-basis: 100%

        // @include min-width-xxl
        //     width: calc(25% - 1rem)
        //     flex-basis: calc(25% - 1rem)

        &-pagination
            text-align: center
            position: absolute
            z-index: 1

        &-img
            width: 100%
            height: 100%
            object-fit: cover
            border-radius: 8px 8px 0 0

            &-empty
                height: 250px
                width: 100%
                object-fit: cover
                border-radius: 8px 8px 0 0

                // @include min-width-xl
                //     height: 320px

                // @include min-width-xxl
                //     height: 270px

                // @include max-width-sm
                //     height: 470px

                // @include max-width-variable(494px)
                //     height: 420px

                // @include max-width-variable(414px)
                //     height: 320px

                // @include max-width-variable(320px)
                //     height: 250px

        &-button

            &-next
                position: absolute
                top: 50%
                transform: translateY(-50%)
                right: 0.5rem
                background-color: #fff
                border-radius: 50%
                opacity: 0.4
                z-index: 1

            &-prev
                position: absolute
                top: 50%
                transform: translateY(-50%)
                left: 0.5rem
                background-color: #fff
                border-radius: 50%
                opacity: 0.4
                z-index: 1  

        &-data
            display: flex
            flex-direction: column
            justify-content: space-between
            height: calc(100% - 250px)
            padding: 16px
            background-color: #FFF
            border-bottom-left-radius: 8px
            border-bottom-right-radius: 8px

            // @include min-width-xl
            //     height: calc(100% - 320px)

            // @include min-width-xxl
            //     height: calc(100% - 270px)

            // @include max-width-sm
            //     height: calc(100% - 470px)

            // @include max-width-variable(494px)
            //     height: calc(100% - 420px)

            // @include max-width-variable(414px)
            //     height: calc(100% - 320px)

            // @include max-width-variable(320px)
            //     height: calc(100% - 250px)

            

            &-name
                font-family: Manrope
                font-size: 18px
                font-weight: 600
                color: #333335
                margin-bottom: 8px

            &-treatment
                font-size: 18px
                color: #333335
                margin-bottom: 8px

                &-block
                    color: #646468

            &-list
                font-size: 14px
                color: #646468

            &-time
                font-size: 18px
                color: #333335

            &-doctor
                font-size: 18px
                color: #333335
                margin-bottom: 8px

    &-show-more
        display: none
        margin-top: 2rem
        text-align: center

        &.--visible
            display: block