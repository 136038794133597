.steps

    &-wrapper
        display: grid
        grid-template-columns: repeat(2, 1fr)
        gap: 2rem
        margin-top: 2rem

        @include max-width-lg
            grid-template-columns: repeat(1, 1fr)

    &-item
        display: block

    &-data
        display: flex
        gap: 1rem
        position: relative
        padding-bottom: 3rem
            
        &-step

            &::after
                content: ''
                position: absolute
                border-left: 1px solid #1B86B8
                height: 100%
                left: 8px
                top: 8px

                @include max-width-xl
                    left: 7px
                    top: 7px

            &-header
                color: #333335
                font-family: Manrope
                font-size: 18px
                font-style: normal
                font-weight: 600

            &-text
                color: #646468

            &-time
                color: #646468

            &-result
                font-size: 18px
                color: #333335

        &-button
            width: 240px

            &-wrapper
                margin: 2rem 0

    &-quote
        display: grid
        grid-template-columns: auto 170px
        gap: 2rem

        @include max-width-xs
            grid-template-columns: repeat(1, 1fr)

        &-block
            position: relative
            border-radius: 8px
            background-color: #E0F5FF
            padding: 1rem
            height: max-content

            &-header
                font-size: 18px
                color: #333335
                margin-bottom: 0.375rem

            &-text
                font-size: 14px
                color: #646468

            &-info
                color: #333335
                // font-size: 18px

            &-medic
                color: #333335
                margin-bottom: 2px

            &-signature
                font-size: 14px
                color: #646468
                margin-bottom: 0

        &-img
            width: 100%
            border-radius: 8px

        &-arrow
            position: absolute
            right: -22px
            top: 16px

            @include max-width-xs
                display: none