.video

    &-modal
        position: relative
        border: 0 !important
        background-color: rgba(255, 255, 255, 0) !important

    &-wrapper
        position: relative
        // width: 400px
        max-width: 100%
        max-height: 100vh
        padding-bottom: 177.7%

        & video
            width: 100%
            height: 100%
            position: absolute
            top: 0
            left: 0
            z-index: 2
            border-radius: 16px

    &-close
        position: absolute
        right: 15px
        top: 15px
        z-index: 3

.modal-sm


    // @include min-width-variable(415px)
    //     margin: auto
    //     max-width: 300px