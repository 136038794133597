.mobile-nav
    position: absolute
    top: 0
    right: -1000px
    width: 320px
    height: 100%
    // max-height: 100vh
    background-color: #fff
    // min-height: 100vh
    transition: all 0.3s
    z-index: 1030
    position: fixed
    box-shadow: $box-shadow
    overflow: scroll
    -webkit-overflow-scrolling: touch

    &.--visible
        right: 0

    &-container
        position: relative
        padding: 1rem

    &-close
        position: absolute
        top: 1rem
        right: 1rem
        font-size: 1.5rem
        cursor: pointer
        border: none
        padding: 0
        background-color: transparent
        color: $gray-color

    &-logo
        width: 100px

    &-search

        &-block
            position: relative
            margin-top: 10px

    &-form-control
        position: relative
        margin-bottom: 1rem

        & label
            cursor: text
            display: block
            position: absolute
            left: 1.575rem
            top: 0.7rem
            color: $gray-color
            transition: .3s ease

        & input
            outline: 0
            border-width: 0 0 1px
            border-color: $gray-color
            border-radius: 0
            padding: 1.075rem 0 0.225rem 1.575rem

            &:not(:placeholder-shown) + label, &:focus + label
                left: 1.575rem
                top: 0.225rem
                font-size: 0.75rem

        &-symbol
            top: 12px
            left: 0px
            font-family: "bootstrap-icons"
            font-size: 16px
            position: absolute
            color: #999999
            transition: all 0.2s

            &::before
                content: "\F52A"

        &-search
            position: relative
            margin-bottom: 0.5rem

            &-title
                font-family: 'Manrope', sans-serif
                font-size: 1.25rem
                color: #333335

            &-button
                position: absolute
                right: 8px
                top: 50%
                color: #1B86B8
                background-color: #fff
                border: none
                transform: translateY(-50%)

                &:hover > i
                    color: $hover-color

                &-close
                    position: absolute
                    top: 18px
                    right: 18px

    &-menu

        &-link
            display: flex
            justify-content: space-between
            align-items: center
            cursor: pointer
            height: 40px
            border-bottom: 1px solid $separator-color
            text-decoration: none

            &[aria-expanded="true"] > .mobile-nav-menu-link-icon
                transform: rotate(-90deg)

            &.include
                display: flex
                justify-content: space-between
                align-items: center
                cursor: pointer
                padding: 1rem
                border-bottom: 1px solid $separator-color
                text-decoration: none

            &-name
                color: $gray-color-700
                font-weight: 600
                margin-bottom: 0

                &.include
                    font-weight: 400

            &-icon
                color: $primary-color

            &:hover > .mobile-nav-menu-link-icon
                color: $hover-color

        &-collapse
            background-color: $light-color

        &-location
            margin-top: 1.5rem
            margin-bottom: 4px

        &-phone
            color: #333335
            text-decoration: none

            &:hover
                color: $hover-color

        &-metro
            display: flex
            align-items: center
            margin-top: 4px

            &-icon
                height: 16px
                margin-right: 4px

            &-name
                color: $gray-color
                margin-bottom: 0

        &-time
            display: flex
            align-items: center
            margin: 1.5rem 0

            &-clock
                height: 18px
                margin-right: 4px

            &-text
                color: $gray-color-700
                margin-bottom: 1px

    &-contacts
        display: none
        text-align: center
        padding-bottom: 1rem

        @include max-width-lg
            display: block

        &-metro
            display: flex
            align-items: center
            justify-content: center
            margin-top: 1rem

            &-img
                height: 18px
                margin-right: 4px

            &-text
                margin-bottom: 0
                color: $gray-color

            &-block
                display: flex
                gap: 12px
                justify-content: center

        &-address
            font-size: 0.9rem
            margin-bottom: 0

        &-hr
            background: radial-gradient(ellipse at center, #17afd1 0%, #fff 75%)
            height: 1px
            border: none
            margin: 10px 0

        &-phone
            color: darken($gray-color, 10%)
            font-size: 1.1rem
            text-decoration: none

            &:hover
                color: $hover-color