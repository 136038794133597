.offers

    &-wrapper
        display: block

        @include max-width-lg
            display: flex
            flex-wrap: wrap
            justify-content: space-between

    &-subtitle
        margin-top: -8px
        font-size: 18px

    &-item
        display: flex
        padding: 1rem
        background: #FFFFFF
        // box-shadow: 6px 6px 12px rgba(217, 217, 217, 0.25)
        border-radius: 16px
        border: 1px solid rgba(132, 133, 138, 0.25)
        margin-top: 2rem

        @include max-width-lg
            flex-direction: column
            flex-basis: calc(50% - 1rem)
         
        @include max-width-xs
            flex-basis: 100%

        &-img
            // height: 100%
            width: 300px
            min-height: 250px
            object-fit: cover
            border-radius: 8px

            @include max-width-lg
                width: 100%

        &-info

            &-wrapper
                display: flex
                align-items: center
                justify-content: space-between

            &-time
                display: none

                &.--visible
                    display: block

                    @include max-width-lg
                        display: none

                &-title
                    font-size: 15px
                    color: #646468
                    margin-bottom: 4px

                &-expiration
                    display: flex
                    gap: 10px

                    &-wrapper
                        display: block

                        &.time
                            margin-left: 1rem

                    &-days, &-hours, &-minutes, &-seconds
                        font-size: 1.5rem
                        font-weight: 600
                        font-family: 'Manrope', sans-serif
                        color: #333335
                        margin-bottom: 0

                    &-text
                        font-size: 13px
                        color: #646468

        &-data
            display: flex
            flex-direction: column
            justify-content: space-between
            padding: 0 1rem
            width: 100%

            @include max-width-lg
                padding: 1rem 0 0

            &-name
                font-size: 22px
                font-weight: 500
                font-family: 'Manrope', sans-serif
                color: #333335
                margin-bottom: 0.25rem
                // text-decoration: underline

                &-wrapper
                    position: relative

                &-link
                    position: absolute
                    opacity: 0
                    display: block
                    top: 0
                    left: 0
                    width: 100%
                    height: 100%

                    &:hover > .offers-item-data-name
                        color: #0e6187

            &-price
                font-size: 18px
                font-weight: 600
                font-family: 'Manrope', sans-serif
                color: #333335
                margin-bottom: 0

            &-old-price

                &-wrapper
                    display: flex
                    align-items: center
                    gap: 10px
                    margin-bottom: 1rem

                &-text
                    color: #84858A
                    margin-bottom: 0
                    line-height: 1
                    text-decoration: line-through
                
                &-badge
                    background: linear-gradient(102deg, #FFDBF2 -10.86%, rgba(194, 220, 242, 0.25) 107.7%)
                    color: #A0136C
                    font-weight: 600
                    padding: 2px 6px
                    border-radius: 4px

            &-off
                color: #646468
                margin-bottom: 1rem

            &-buttons
                display: flex
                gap: 10px
                padding-bottom: 3rem

                @include max-width-lg
                    flex-direction: column
                    width: 100%
                    text-align: center
                    padding-bottom: 0

            &-social

                &-title
                    font-size: 14px
                    color: #646468
                    margin-top: 1rem
                    margin-bottom: 8px

                &-placeholder
                    width: 140px
                    height: 22px
                    border-radius: 4px
                    animation: $animation

                &-buttons
                    display: flex
                    gap: 8px

                &-button
                    text-decoration: none

                    &-img
                        height: 24px
                        width: 24px
                        border-radius: 4px

            &-clinic

                &-wrapper
                    display: flex
                    align-items: center
                    gap: 10px
                    padding: 0.375rem 0 0.75rem

                    @include max-width-lg
                        flex-direction: column
                        align-items: baseline

                &-block
                    display: flex
                    align-items: center
                    gap: 10px

                &-text
                    color: #646468
                    // font-size: 18px
                    margin-bottom: 0

                    @include max-width-lg
                        line-height: 0.5
                        margin-top: 8px

                &-subway

                    &-block
                        display: flex
                        align-items: center

                    &-icon
                        height: 16px
                        margin-right: 4px

                    &-text
                        color: #84858A

    &-page

        &-content
            margin-top: 2rem

        &-title
            margin-bottom: 1rem