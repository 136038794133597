.technologies

    &-wrapper
        display: flex
        flex-wrap: wrap
        margin-top: 2rem
        gap: 1rem

    &-item
        display: flex
        gap: 1rem
        flex-basis: calc(50% - 1rem)

        @include max-width-md
            flex-basis: 100%

        @include max-width-xl
            display: block

        &-img
            position: absolute
            top: 1rem
            left: 1rem
            height: calc(100% - 2rem)
            width: calc(100% - 2rem)
            object-fit: contain

            &-block
                background-color: #f6f6f6
                position: relative
                height: 100%
                width: 250px
                flex-basis: 50%
                border-radius: 8px

                @include max-width-xl
                    width: 100%
                    height: 250px
                    margin-bottom: 1rem

        &-data
            flex-basis: 50%

        &-title
            color: $second-color
            margin-bottom: 0.5rem

        &-text
            font-size: 14px
            color: #646468