.implantation-installment

    &-wrapper
        display: grid
        grid-template-columns: 240px auto
        gap: 1rem
        position: relative
        background: linear-gradient(101.66deg, #FFDBF2 -10.86%, rgba(194, 220, 242, 0.25) 107.7%)
        border-radius: 8px
        padding: 1.5rem

        @include max-width-lg
            display: flex
            flex-direction: column

        @include min-width-xl
            grid-template-columns: 267px auto

        @include max-width-sm
            padding-bottom: 0
            padding-left: 0

    &-data
        @include max-width-sm
            padding-left: 1rem

        &-title
            font-family: 'Manrope', sans-serif
            font-size: 1.1rem
            line-height: 1.2
            font-weight: 600

        &-offer
            font-family: 'Manrope', sans-serif
            font-size: 1.5rem
            line-height: 1.2
            font-weight: 500
            color: #A0136C
            margin-bottom: 1.5rem

    &-options

        &-block
            display: flex
            gap: 2rem
            background: rgba(255, 255, 255, 0.5)
            box-shadow: 6px 6px 12px rgba(217, 217, 217, 0.25)
            border-radius: 16px
            padding: 24px 16px
            height: max-content
            align-self: center
            padding-right: 8rem

            @include max-width-lg
                padding-right: 0
                align-self: initial

            @include max-width-md
                gap: 1.2rem

            @include max-width-sm
                padding: 1rem 0
                background: none
                box-shadow: none
                flex-direction: column

        &-item
            flex-basis: 33.3333%

            @include max-width-sm
                background: rgba(255, 255, 255, 0.5)
                box-shadow: 6px 6px 12px rgba(217, 217, 217, 0.25)
                border-radius: 0 16px 16px 0
                padding: 1rem

        &-title
            font-size: 0.9rem
            color: #646468

            @include max-width-sm
                margin-bottom: 0.5rem

        &-offer
            font-family: 'Manrope', sans-serif
            font-size: 1.1rem
            line-height: 1.2
            font-weight: 600
            margin-bottom: 0

    &-img
        position: absolute
        right: 0
        top: 0
        height: 100%

        @include max-width-lg
            height: 50%
            top: 50%

        @include max-width-md
            display: none

        @include max-width-sm
            position: relative
            display: block
            height: 230px
            object-fit: contain
            padding-left: 1rem