.services

    &-wrapper
        // background: linear-gradient(99.07deg, #FEFEFE 3.57%, #E5F2F9 76.37%)
        background-color: #F5FCFF

    &-info

        &-block
            display: grid
            grid-template-columns: 470px 220px auto
            gap: 1rem

            @include max-width-lg
                display: flex
                flex-direction: column
                gap: 1.5rem
                align-items: baseline

            @include min-width-xl
                grid-template-columns: 600px 300px auto

        &-button
            @include max-width-xs
                display: block
                width: 100%

            &-wrapper
                @include max-width-xs
                    width: 100%
                    text-align: center

        &-title
            font-size: 1.75rem
            font-weight: 600
            color: $header-text-color
            margin-bottom: 8px
            line-height: 1.2
            font-family: 'Manrope', sans-serif

        &-subtitle
            color: $gray-color-700

        &-contacts

            &-title
                font-family: 'Manrope', sans-serif
                font-size: 18px
                font-weight: 600
                // color: #A0136C

            &-phone
                font-family: 'Manrope', sans-serif
                font-size: 18px
                font-weight: 600
                color: #646468
                text-decoration: none

            &-subway
                color: #646468
                margin-bottom: 0

                &-wrapper
                    display: flex
                    align-items: center
                    margin-top: 4px
                    margin-bottom: 1rem

                &-img
                    height: 16px
                    margin-right: 4px

                &-block
                    display: flex
                    gap: 12px

        &-whatsapp
            width: 100%

    &-header
        font-size: 1.75rem
        color: $header-text-color

    &-block
        display: flex
        flex-wrap: wrap
        justify-content: center
        gap: 1rem
        margin-top: 32px

        @include min-width-xxl
            gap: 2rem

        &-buttons
            display: flex
            gap: 10px

            &-link
                font-weight: 600

        &-item
            display: flex
            flex-direction: column
            justify-content: center
            flex: 0 0 calc(33.333333% - 1rem)
            background-color: #fff
            border-radius: 8px
            padding: 20px
            width: 100%
            text-decoration: none

            @include min-width-xxl
                flex: 0 0 calc(33.333333% - 2rem)

            @include min-width-lg
                min-height: 111px

            @include max-width-lg
                flex-basis: 100%

        &-group
            display: flex
            align-items: center
            gap: 0.375rem
            margin-bottom: 0.75rem

            &-img
                width: 32px
                height: 32px

                @include max-width-lg
                    width: 30px
                    height: 30px

            &-text
                font-family: Manrope
                font-size: 18px
                font-style: normal
                font-weight: 600
                color: #333335
                margin-bottom: 0

                @include max-width-lg
                    margin-top: 0
                    font-size: 17px

        &-links
            margin-top: 0.25rem


            // @include max-width-lg
                // margin-left: calc(30px + 0.375rem)
