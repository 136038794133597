.article-page

    &-title
        color: #333335
        font-family: Manrope
        font-size: 1.75rem
        font-style: normal
        font-weight: 600
        line-height: 130%

    &-button-wrapper
        text-align: center
        margin-top: 1.5rem

    &-slides
        position: relative
        margin-top: 2rem

    &-slide

        &-data
            display: flex
            flex-direction: column
            justify-content: space-between
            height: calc(100% - 250px)
            // padding: 16px
            // background-color: #F5FCFF
            border-bottom-left-radius: 8px
            border-bottom-right-radius: 8px

            &-name
                color: #333335
                font-family: Manrope
                font-size: 18px
                font-style: normal
                font-weight: 600
                margin: 1rem 0 4px
            

            &-text
                color: #646468
                margin-bottom: 8px

            &-link-wrapper
                text-align: right

    &-card
        height: 250px

    &-item
        width: calc(33.333333% - 1rem)
        flex-basis: calc(33.333333% - 1rem)

        @include max-width-lg
            width: calc(50% - 1rem)
            flex-basis: calc(50% - 1rem)

        @include max-width-sm
            width: 100%
            flex-basis: 100%

        @include min-width-xxl
            width: calc(25% - 1rem)
            flex-basis: calc(25% - 1rem)

        &-pagination
            text-align: center
            position: absolute
            z-index: 1

        &-img
            width: 100%
            height: 100%
            object-fit: cover
            border-radius: 8px 8px 0 0

            &-empty
                height: 250px
                width: 100%
                object-fit: cover
                border-radius: 8px 8px 0 0
