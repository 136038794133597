.staff

    &-breadcrumb
        display: none

        @include max-width-lg
            display: block
            margin-top: 2rem

        &-desktop
            @include max-width-lg
                display: none

    &-data
        font-family: Manrope
        font-size: 18px
        color: #333335

        & p strong
            font-family: Manrope
            font-size: 24px
            font-style: normal
            font-weight: 500

            @include max-width-lg
                font-size: 24px

        & strong
            font-weight: 600

        &-title
            font-family: Manrope
            font-size: 28px
            font-weight: 500
            color: #333335
            margin-bottom: 1.5rem

            @include max-width-lg
                font-size: 24px

    &-page

        &-video

            &-button
                font-weight: 600
                color: #2798CD

                &-block
                    display: flex
                    align-items: center
                    gap: 8px
                    cursor: pointer
                    padding-top: 1rem

                    &.--hidden  
                        display: none

                &-img
                    height: 40px
                    width: 40px
                    cursor: pointer

        &-header
            position: relative
            // padding: 3rem 0 4rem
            background: linear-gradient(90.14deg, #FFF 45.55%, rgba(245, 252, 255, 0) 64.54%)

            @include max-width-lg
                background: none
                padding-bottom: 2rem

            &-cover
                position: absolute
                right: 0
                top: 0
                height: 100%
                width: 55%
                z-index: -1

                &::before
                    @include max-width-lg
                        content: ''
                        position: absolute
                        top: 0
                        left: 0
                        right: 0
                        bottom: 0
                        background: linear-gradient(180deg, #FFF 0%, rgba(245, 252, 255, 0) 15%)

                @include max-width-lg
                    position: relative
                    width: 100%

                &-img
                    height: 100%
                    width: 100%
                    object-fit: cover

                    @include max-width-lg
                        height: auto

            &-data
                display: flex
                flex-direction: column
                justify-content: space-between
                // background: linear-gradient(90.14deg, #F5F5F5 47.55%, rgba(245, 252, 255, 0) 81.54%)
                padding: 2rem 0

                // @include max-width-lg
                //     margin: 1rem
                //     width: calc(100% - 2rem)
                //     background-color: #F5F5F5
                //     border-radius: 8px
                //     margin-top: -10rem

            &-last-name
                font-family: Manrope
                font-size: 36px
                color: #333335
                margin-bottom: 8px

                @include max-width-lg
                    font-size: 26px

            &-first-name
                font-family: Manrope
                font-size: 36px
                color: #333335
                margin-bottom: 1rem

                @include max-width-lg
                    font-size: 26px

            &-position
                font-size: 18px
                font-weight: 500
                color: #333335
                margin-bottom: 1rem

            &-experience
                font-size: 18px
                color: #646468
                margin-bottom: 0.75rem

            &-btn
                min-width: 190px

            &-clinic

                &-block
                    display: flex
                    gap: 12px
                    margin-bottom: 1.25rem

                &-text
                    color: #646468
                    font-size: 18px
                    margin-bottom: 0

                &-subway
                    display: flex
                    align-items: center
                    gap: 5px

                    &-img
                        height: 16px

                    &-text
                        color: #646468
                        font-size: 18px
                        margin-bottom: 0
        &-menu
            display: flex
            flex-wrap: wrap
            gap: 2rem

            @include max-width-lg
                gap: 0.75rem

            &-link
                color: $gray-color-700
                text-decoration: none
                cursor: pointer

                @include max-width-lg
                    flex: calc(50% - 0.75rem)

                &:hover
                    color: #1B86B8

        &-about
            padding-top: 2rem
            // background: linear-gradient(76.81deg, #B6D4F0 0.99%, #E5F2F9 20.26%, #FEFEFE 52.01%)
            // background: linear-gradient(96.81deg, #FEFEFE 0.99%, #E5F2F9 80.26%, #B6D4F0 99.01%)

            @include max-width-lg
                margin-top: -4rem

            &-medical
                display: flex
                justify-content: space-between
                margin-top: 2rem
                gap: 2rem

                @include max-width-lg
                    flex-direction: column

                &-title
                    font-size: 1.5rem
                    color: #333335

                &-text
                    color: $gray-color-700

                    &-block
                        max-width: 800px
                        width: 100%

                    &-wrapper
                        margin-top: 1rem
                        font-size: 18px
                        color: #333335

                        // @include max-width-lg
                        //     display: none

                        // &-mobile
                        //     display: none

                        //     @include max-width-lg
                        //         display: block

                &-img
                    height: 360px
                    width: 300px
                    border-radius: 8px
                    object-fit: cover

                    @include max-width-lg
                        width: 100%
                        height: auto
                        max-width: 400px

    &-gallery
        position: relative
        margin-top: 2rem

    &-slide
        align-self: center

        @include max-width-lg
            width: 280px !important

        &-img
            width: 100%

    &-group

        &-title
            font-size: 24px
            font-weight: 500
            font-family: 'Manrope', sans-serif
            color: #333335
            margin-top: 1rem

    &-card

        &-wrapper
            display: flex
            justify-content: space-between
            flex-wrap: wrap
            gap: 2rem
            margin: 2rem 0

        &-item
            position: relative
            display: flex
            flex-basis: calc(50% - 1rem)
            background: #F8F8F8
            box-shadow: 6px 6px 12px rgba(217, 217, 217, 0.25)
            border-radius: 8px

            @include max-width-lg
                flex-basis: 100%

            @include max-width-xs
                flex-direction: column

            &-experience
                position: absolute
                left: 8px
                top: 8px
                font-size: 14px
                color: #2EA3DA
                background-color: #FFFFFF
                border-radius: 24px
                z-index: 1

                &.--showing
                    padding: 3px 8px

            &-img
                height: 100%
                max-height: 280px
                width: 200px
                min-width: 200px
                object-fit: cover
                border-radius: 8px 0px 0px 8px

                @include max-width-xs
                    width: 100%
                    height: 540px
                    max-height: 540px
                    object-position: top center
                    border-radius: 8px 8px 0px 0px

                @include max-width-variable(414px)
                    height: 490px
                    max-height: 490px

                @include max-width-variable(340px)
                    height: 430px
                    max-height: 430px

            &-data
                display: flex
                flex-direction: column
                padding: 1rem
                width: 100%

                &.medical
                    justify-content: space-between

            &-footer
                display: flex
                align-items: center
                justify-content: space-between

            &-last-name, &-first-name
                font-size: 18px
                font-weight: 600
                font-family: 'Manrope', sans-serif
                color: #333335
                margin-bottom: 0

            &-position
                color: #646468
                margin: 1rem 0

            &-short
                color: #646468

            &-link
                @include max-width-xs
                    text-align: right
