@keyframes scale
    30%
        transform: scale(0.3)
    100%
        transform: scale(1)

.loader > div:nth-child(0)
    animation: scale 0.75s -0.36s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08)

.loader > div:nth-child(1)
    animation: scale 0.75s -0.24s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08)

.loader > div:nth-child(2)
    animation: scale 0.75s -0.12s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08)
    
.loader > div:nth-child(3)
    animation: scale 0.75s 0s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08)

.loader
    display: flex
    align-items: center
    justify-content: center
    background-color: #2798cd
    width: 4rem
    height: 1.75rem
    border-radius: $border-radius

    & > div
        background-color: #fff
        width: 8px
        height: 8px
        border-radius: 50%
        margin: 4px
        animation-fill-mode: both

    &-wrapper
        display: none
        justify-content: center
        align-items: center
        height: 10rem
        width: 100%

        &.--visible
            display: flex

    &-page
        display: flex
        justify-content: center
        align-items: center
        height: calc(100vh - 280px)
        width: 100%
        // background-color: rgba(255, 255, 255, 0.75)
        z-index: 2

        &.--hidden
            display: none