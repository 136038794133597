.braces-gnathology

    &-wrapper
        display: grid
        grid-template-columns: calc(50% - 1.5rem) calc(50% - 1.5rem)
        gap: 3rem
        margin-top: 2rem

        @include max-width-lg
            display: flex
            flex-direction: column

    &-target
        display: flex
        flex-direction: column

        &-img
            border-radius: 8px
            box-shadow: 6px 6px 12px 0px rgba(147, 147, 147, 0.25)
            height: 100%
            object-fit: cover
            overflow: hidden

        &-text
            color: #646468
            font-size: 1.1rem

            & span
                font-weight: 600

    &-equipment
        display: flex
        flex-direction: column

        &-img
            order: 0
            width: 100%
            border-radius: 8px
            box-shadow: 6px 6px 12px 0px rgba(147, 147, 147, 0.25)
            margin-bottom: 1rem
            height: 100%
            object-fit: cover

            @include max-width-lg
                order: 1

        &-data
            order: 1

            @include max-width-lg
                order: 0

            &-title
                color: #646468
                font-family: Manrope
                font-size: 1.2rem
                font-weight: 600

            &-text
                color: #646468
                font-size: 1.1rem

        &-info
            order: 2
            border-radius: 8px
            background-color: #1B9BB8
            padding: 8px 12px
            color: #fff
            font-size: 1.1rem
            font-weight: 600

        
