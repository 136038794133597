.offer-page

    &-content
        font-family: Manrope
        font-size: 18px
        color: #333335
        padding-right: 2rem

        @include max-width-lg
            padding-right: 0

        & h1
            font-family: Manrope
            font-size: 24px
            font-style: normal
            font-weight: 500
            line-height: 2

            @include max-width-lg
                font-size: 24px

        & strong
            font-weight: 600

    &-modal

        &-close
            position: absolute
            top: 12px
            right: 12px

        &-content
            padding: 1rem
            background-color: #F5FEFF !important
            border-radius: 16px !important
            border: 0 !important

        &-info
            font-size: 15px
            color: #646468
            margin-bottom: 0
            padding-right: 2rem

            & span
                color: $second-color

            &-block
                display: flex
                gap: 1rem
                margin-top: 1rem

    &-top

        &-header
            display: grid
            grid-template-columns: 60% 40%
            margin: 1rem 0

            @include max-width-lg
                grid-template-columns: repeat(1, 1fr)

            &-img
                width: 100%
                border-radius: 8px

                @include max-width-lg
                    margin-top: 1rem

    &-header
        padding: 3rem 0 4rem
        background-image: url('../img/static/header-implantation.png'), linear-gradient(101.66deg, #FFDBF2 -10.86%, rgba(194, 220, 242, 0.25) 107.7%)
        background-repeat: no-repeat
        background-position: center right
        background-size: contain

        @include max-width-lg
            background: linear-gradient(101.66deg, #FFDBF2 -10.86%, rgba(194, 220, 242, 0.25) 107.7%) 

        &-mobile
            display: none

            @include max-width-lg
                display: block

            &-info

                &-block
                    font-size: 15px
                    color: #646468
                    margin-bottom: 2rem

                    &-link
                        color: $second-color
                        text-decoration: underline

                        &:hover
                            color: $second-color

                        & img
                            height: 14px
                            margin-left: 4px
                            margin-bottom: 2px

        &-desktop
            @include max-width-lg
                display: none

    &-breadcrumb
        display: none

        @include max-width-lg
            display: block
            margin-top: 0.5rem

        &-desktop
            @include max-width-lg
                display: none

    &-data
        padding: 2rem 0

    &-about
        // width: 470px

        @include max-width-lg
            width: auto

        &-image
            height: 200px
            width: 200px
            object-fit: cover
            border-radius: 50%
            display: none
            margin: auto

            @include max-width-lg
                display: block

        &-title
            font-size: 2rem

        &-subtitle
            color: $gray-color-700
            font-size: 1.2rem
            margin-top: 1.5rem

            @include max-width-lg
                margin-top: 0.75rem
                margin-bottom: 1rem

        &-second-title
            // color: $gray-color-700
            font-size: 1.2rem
            // margin-top: 1.5rem

            @include max-width-lg
                margin-top: 0.75rem
                margin-bottom: 1rem

    &-advantages
        display: none
        margin-top: 1rem
    
        @include max-width-lg
            display: block
            margin-bottom: 1rem

        &-item
            display: flex
            align-items: center

            &-text
                margin-bottom: 0
                color: $gray-color-700

            &-icon
                line-height: 1.25
                margin-top: -4px
                font-size: 2rem
                margin-right: 4px
                color: $second-color

    &-info

        &-wrapper
            display: flex
            gap: 1rem
            margin-top: 1.5rem

        &-block
            display: flex
            gap: 1rem
            padding: 0.5rem 1rem
            background-color: #FFFFFF
            opacity: 0.75
            box-shadow: 0px 199px 80px rgba(36, 202, 214, 0.01), 0px 112px 67px rgba(36, 202, 214, 0.05), 0px 50px 50px rgba(36, 202, 214, 0.09), 0px 12px 27px rgba(36, 202, 214, 0.1), 0px 0px 0px rgba(36, 202, 214, 0.1)
            border-radius: 8px
            max-width: 470px

            &-item
                display: block
                
                &-wrapper
                    display: flex

                &-img
                    height: 36px
                    width: 36px

                &-text
                    font-size: 15px
                    color: #646468
                    margin-bottom: 0
                    line-height: 1

                &-data
                    // font-size: 15px
                    color: #333335
                    margin-bottom: 0

                &-footnote
                    font-size: 15px
                    color: #646468
                    margin-bottom: 0
                    line-height: 1.25

                    & span
                        color: $second-color

                &-offer
                    font-size: 15px
                    color: $second-color
                    margin-bottom: 0
                    line-height: 1.25

                    & span
                        text-decoration: underline

                &-count
                    flex: none

    &-validity
        color: #646468