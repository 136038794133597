.excellence

    &-block
        background-image: url('../img/static/dentis.svg'), linear-gradient(99.07deg, #FEFEFE 4%, #E5F2F9 77%)
        background-repeat: no-repeat
        background-position: center right
        background-size: contain
        padding: 30px 0


    &-wrapper
        display: flex
        flex-wrap: wrap
        gap: 2rem
        margin-top: 2rem

        @include max-width-md
            gap: 1rem

    &-item
        flex: 0 0 calc(33.333333% - 2rem)
        margin-bottom: 1.5rem

        @include max-width-lg
            flex: 0 0 calc(50% - 2rem)

        @include max-width-md
            flex: 100%

        &-block
            display: block

            @include max-width-md
                display: flex
                align-items: flex-start
                margin-bottom: 8px

        &-img
            height: 32px
            width: 32px
            border: 1px solid $primary-color
            border-radius: 50%
            padding: 4px
            margin-bottom: 8px

            @include max-width-md
                margin-bottom: 0
                margin-right: 8px

        &-title
            font-size: 18px
            font-weight: 600
            margin-bottom: 0
            color: #333335

        &-description
            color: $gray-color-700
            font-size: 14px
            margin-bottom: 0