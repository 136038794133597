.open-day

    &-header
        padding: 3rem 0 0
        background: linear-gradient(99.64deg, #EBF7FF 9.3%, #F7EDFF 80.36%)

        &-button
            @include max-width-md
                width: 100%

    &-content
        display: flex
        align-items: center
        position: relative
        padding: 1rem 0 4rem
        background: linear-gradient(99.64deg, #EBF7FF 9.3%, #F7EDFF 80.36%)
        background-repeat: no-repeat
        background-position: center right
        background-size: contain

        @include max-width-lg
            height: 580px
            display: flex
            flex-direction: column
            justify-content: space-between

    &-section
        padding: 3rem 0

        &-clinics
            margin-bottom: -2rem
            padding-bottom: 0

    &-block
        border-radius: 8px
        border: 2px solid var(--dentis-colors-200, #98EFF9)
        box-shadow: 6px 6px 12px 0px rgba(217, 217, 217, 0.25)
        display: grid
        grid-template-columns: calc(50% - 12px) calc(50% - 12px)
        gap: 32px
        padding: 24px 36px

        @include max-width-lg
            display: block
            padding-bottom: calc(2rem + 24px)

        @include max-width-xs
            padding: 8px 16px
            padding-bottom: calc(2rem + 16px)

    &-item
        position: relative

        @include max-width-lg
            min-height: 180px

        &-title
            font-size: 2rem
            font-family: Manrope
            font-weight: 700
            line-height: 120%

            & span
                color: #1B9BB8
                font-size: 2.1rem

        &-about
            font-size: 1.25rem
            font-family: Manrope
            font-weight: 500
            color: #333335

        &-section
            display: flex
            align-items: center
            gap: 1rem

            @include max-width-sm
                display: block

            &-wrapper
                display: flex
                flex-direction: column
                gap: 1rem

            &-span
                border-radius: 48px
                border: 2px solid #A0136C
                color: #A0136C
                padding: 8px 24px
                font-size: 20px
                font-family: Manrope
                font-weight: 600

                @include max-width-sm
                    font-size: 1rem
                    padding: 4px 16px

            &-text
                font-size: 18px
                color: #646468
                margin-bottom: 0

                @include max-width-sm
                    margin: 1rem 0

                & span
                    font-weight: 600

    &-img
        position: absolute
        border-radius: 8px
        height: 100%
        width: 100%
        object-fit: cover

        @include max-width-lg
            margin-top: 2rem

    &-price

        &-block
            display: flex
            gap: 0.375rem
            align-items: center
            margin: 2rem 0

            @include max-width-md
                display: block

            @include max-width-sm
                margin: 1rem 0 1.5rem 0

            &-data
                display: flex
                gap: 0.5rem
                align-items: center

        &-name
            font-size: 1.25rem
            font-family: Manrope
            font-weight: 600
            color: #1B9BB8
            margin-bottom: 0

        &-cost
            font-size: 1.25rem
            font-family: Manrope
            font-weight: 600
            color: #333335
            margin-bottom: 0

        &-old
            font-size: 14px
            color: #84858A
            text-decoration: line-through
            margin-bottom: 0

        &-span
            color: #A0136C
            font-weight: 600
            margin-bottom: 0
            padding: 0 6px
            padding-bottom: 2px
            border-radius: 4px
            background: var(--dentis-colors-gradient-01, linear-gradient(135deg, #FFDBF2 0%, rgba(194, 220, 242, 0.25) 100%))

    &-window

        &-content
            display: flex
            gap: 1rem
            align-items: center
            padding: 0.25rem
            background-color: #FFF !important
            border-radius: 16px !important
            border: 0 !important

            @include max-width-lg
                flex-direction: column

        &-close
            position: absolute
            top: 1rem
            right: 1rem
            z-index: 1

        &-img
            width: 100%

            @include max-width-lg
                width: 50%

            &-block
                flex-basis: 40%

                @include max-width-lg
                    display: flex
                    align-items: flex-end
                    gap: 1rem

            &-name
                font-family: Manrope
                font-size: 1.1rem
                font-weight: 600
                margin: 4px 0

            &-position
                font-size: 14px
                color: #646468
                margin-bottom: 0

        &-block
            flex-basis: 60%

        &-title
            font-family: 'Manrope', sans-serif
            font-size: 1.5rem
            font-weight: 500
            color: #333335
            margin-bottom: 1rem
            line-height: 1.2
            max-width: 450px

            & span
                font-size: 1.6rem
                color: #1B9BB8

        &-subtitle
            color: #333335

        &-item
            display: flex
            align-items: center
            gap: 0.5rem

            &-ellipse
                width: 8px
                height: 8px
                border-radius: 8px
                background-color: #A0136C

            &-text
                font-size: 15px
                color: #646468
                margin-bottom: 2px

                & span
                    color: #A0136C
                    font-weight: 600

        &-offer
            margin-top: 1rem
            font-size: 1.1rem
            color: #A0136C