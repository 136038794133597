.service-braces

    &-header
        position: relative
        padding: 3rem 0 4rem
        background: linear-gradient(90.14deg, #FFF 45.55%, rgba(245, 252, 255, 0) 62.54%)
        margin-bottom: 2rem

        @include max-width-lg
            background: linear-gradient(180deg, #FFF 44.35%, rgba(255, 255, 255, 0.00) 65.92%)
            min-height: 800px

        &-cover
            position: absolute
            right: 0
            top: 0
            height: 100%
            width: 55%
            z-index: -1

            @include max-width-lg
                margin-top: 350px
                width: 100%
                height: 400px

            &-img
                height: 100%
                width: 100%
                object-fit: cover

                @include max-width-lg
                    object-position: bottom

        &-data
            display: flex
            flex-direction: column
            justify-content: space-between

            &-list
                margin: 0.5rem 0 1.5rem
                display: flex
                flex-direction: column
                gap: 1rem

                &-item
                    display: flex
                    align-items: center
                    gap: 7px

                    &-text
                        color: #646468

            &-title
                font-family: Manrope
                color: #333335
                font-size: 32px
                font-weight: 500
                max-width: 400px
                margin: 1rem 0 1.5rem
                line-height: 130%

                @include max-width-lg
                    font-size: 20px

            &-subtitle
                font-size: 18px
                color: #646468
                margin-bottom: 1.5rem

        &-button
            margin-bottom: 2rem

    &-price
        display: grid
        grid-template-columns: 30% auto
        gap: 2rem

        @include max-width-xl
            grid-template-columns: repeat(1, 1fr)

        &-title
            color: #333335
            font-family: Manrope
            font-size: 36px
            font-style: normal
            font-weight: 500

            @include max-width-xxl
                font-size: 32px

            @include max-width-lg
                font-size: 24px

        &-subtitle
            color: #646468
            font-size: 18px

        &-item
            display: flex
            flex-direction: column
            border-radius: 8px
            background: #FFF
            box-shadow: 6px 6px 12px 0px rgba(217, 217, 217, 0.25)
            overflow: hidden

            &:first-child
                @include max-width-md
                    margin-bottom: 2rem

            &-wrapper
                display: grid
                grid-template-columns: repeat(2, 1fr)
                gap: 2rem

                @include max-width-md
                    display: block

            &-header
                order: 1
                padding: 24px 16px 16px

                @include max-width-md
                    order: 1

                &-title
                    color: #333335
                    font-family: Manrope
                    font-size: 18px
                    font-style: normal
                    font-weight: 600
                    margin-bottom: 2px

                &-subtitle
                    color: #84858A
                    font-size: 14px
                    margin-bottom: 0

                    @include min-width-xl
                        font-size: 13px

            &-img
                order: 2
                width: 100%

                @include max-width-md
                    order: 3

            &-price
                order: 3
                display: grid
                grid-template-columns: 50% 50%
                background-color: #E0F5FF
                padding: 12px 16px
                align-items: center

                @include max-width-md
                    order: 2

                &-data
                    color: #333335
                    font-size: 18px
                    line-height: 120%
                    margin-bottom: 0

                    @include max-width-lg
                        font-size: 16px

                &-cost
                    color: #333335
                    text-align: right
                    font-family: Manrope
                    font-size: 22px
                    font-style: normal
                    font-weight: 700
                    margin-bottom: 0

                    @include max-width-lg
                        font-size: 18px

            &-kind
                order: 4
                padding: 1rem

                @include max-width-md
                    display: none

                &.--mobile
                    display: none
                    background-color: #fff
                    border-radius: 8px

                    @include max-width-md
                        display: block

                &-block
                    display: flex
                    justify-content: space-between
                    align-items: center
                    gap: 1rem
                    border-bottom: 1px solid #E8E9F2
                    padding: 0.5rem 0

                    &:first-child
                        padding-top: 0

                    &:last-child
                        margin-bottom: 2rem

                    &-text
                        color: #646468
                        margin-bottom: 0

                        &.data
                            flex: none

                    &-button
                        width: 240px
                        display: block
                        margin: auto

                        @include max-width-xs
                            width: 100%

                        &-wrapper
                            margin-top: 1rem

        &-mobile

            &-title
                color: #333335
                font-size: 18px
                font-weight: 600
                margin-bottom: 0
                padding: 1rem 0

                @include min-width-md
                    display: none