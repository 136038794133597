.menu
    background-color: #fff
    width: 100%
    z-index: 2
    padding: 1rem 0
    box-shadow: 0 .125rem .125rem -0.125rem rgba(31,27,45,.08),0 .25rem .75rem rgba(31,27,45,.08)

    @include max-width-lg
        padding: 0

    &-wrapper
        width: 100%
        z-index: 999
        background-color: #fff

    &-wrapper
        display: flex
        align-items: center
        height: 80px
        justify-content: space-between

    &-logo
        width: 160px
        height: 72px

        @include max-width-lg
            width: 130px
            height: 60px

    &-data-wrapper
        display: flex
        align-items: flex-start
        width: 100%
        justify-content: space-between
            

        @include max-width-lg
            display: none

    &-contacts
        margin-left: 8rem

        @include min-width-xxl
            margin-left: 12rem

        &-phone
            text-decoration: none
            color: black

            &:hover
                color: $primary-color

            & img
                margin-right: 4px
                margin-bottom: 1px

        &-metro
            display: flex
            align-items: center

            &-img
                height: 18px
                margin-right: 4px

            &-text
                margin-bottom: 0
                color: $gray-color

            &-block
                display: flex
                gap: 12px

        &-address
            font-size: 0.9rem
            margin-bottom: 0

        &-working
            display: flex
            align-items: center

            &-img
                height: 18px
                margin-right: 4px

            &-text
                margin-bottom: 0
                color: $gray-color

            &-time
                font-size: 0.9rem
                margin-bottom: 0

    &-button
        position: relative
        display: none
        border: none
        padding: 0
        background-color: transparent
        cursor: pointer
        font-size: 2rem
        color: $primary-color
        margin-right: 0.5rem

        @include max-width-lg
            display: block
            margin-left: auto

    &-callback

        &-wrapper
            display: flex
            gap: 1rem
            margin-left: 2rem
            margin-top: 5px

            @include min-width-xxl
                margin-left: auto

        &-button
            border-radius: 50%

            @include max-width-xl
                padding: 5px 10px !important

            &::after
                content: 'Записаться'

                @include max-width-xl
                    font-family: "bootstrap-icons"
                    content: "\F414"
                    margin-top: 1px

            &-whatsapp
                color: #fff
                background-color: #0AD10E
                border-color: #0AD10E
                font-size: 19px
                line-height: 1
                font-weight: 400
                border: 1px solid transparent
                border-radius: 50%
                padding: 8px 9px
                cursor: pointer

                @include max-width-xl
                    

                &:hover
                    background-color: #45cb47
                    border-color: #45cb47
                    color: #fff