// :root
    
$form-check-input-checked-bg-color: #2798CD
$form-check-input-checked-border-color: #2798CD

$input-border-color: #98DAF9
$input-border-radius: 48px
$input-padding-x: 1rem
$input-font-family: 'Source Sans Pro'
$input-placeholder-color: #AFB0B6

$breadcrumb-font-size: 14px
$breadcrumb-margin-bottom: 2rem

$modal-sm: 360px
$modal-lg: 650px
$modal-xl: 815px

