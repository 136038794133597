.stop-scroll
    overflow: hidden

    &::after
        content: ''
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0
        background: rgba(0,0,0,.5)
        z-index: 1000
        
