.works

    &-card
        height: 320px

        @include min-width-xl
            height: 330px

        @include min-width-xxl
            height: 380px

        @include max-width-sm
            height: 290px

        @include max-width-variable(494px)
            height: 230px

        @include max-width-variable(414px)
            height: 210px

        @include max-width-variable(320px)
            height: 160px

    &-img
        height: 320px
        width: 100%
        object-fit: cover
        border-radius: 8px 8px 0 0

        @include min-width-xl
            height: 330px

        @include min-width-xxl
            height: 380px

        @include max-width-sm
            height: 290px

        @include max-width-variable(494px)
            height: 230px

        @include max-width-variable(414px)
            height: 210px

        @include max-width-variable(320px)
            height: 160px

    &-watermark
        position: absolute
        height: 40px
        bottom: -36px
        left: 12px

        @include min-width-xxl
            bottom: -68px

        @include max-width-md
            bottom: -15px

        @include max-width-variable(494px)
            bottom: 30px

        @include max-width-variable(414px)
            bottom: 50px

        @include max-width-variable(320px)
            bottom: 100px