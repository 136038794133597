.header
    padding: 3rem 0 4rem 
    background-image: url('../img/static/header.png'), linear-gradient(101.66deg, #FFDBF2 -10.86%, rgba(194, 220, 242, 0.25) 107.7%) 
    background-repeat: no-repeat
    background-position: center right
    background-size: contain

    @include max-width-lg
        background: linear-gradient(101.66deg, #FFDBF2 -10.86%, rgba(194, 220, 242, 0.25) 107.7%) 

    &-about
        width: 470px

        @include max-width-lg
            width: auto
            display: flex
            flex-direction: column
            height: 100%

        &-image
            height: 200px
            width: 200px
            object-fit: cover
            border-radius: 50%
            display: none
            margin: auto

            @include max-width-lg
                display: block

        &-title
            font-size: 2rem

        &-subtitle
            color: $gray-color-700
            font-size: 1.2rem
            margin-top: 1.5rem

            @include max-width-lg
                margin-top: 0.75rem
                margin-bottom: 1rem

        &-description
            display: none
            font-size: 14px
            color: $gray-color-700
            text-align: center

            @include max-width-lg
                display: block
                margin-top: 1rem

    &-advantages
        display: block
        margin-top: 1rem
    
        @include max-width-lg
            display: none

        &-item
            display: flex
            align-items: center

            &-text
                margin-bottom: 0
                color: $gray-color-700

            &-icon
                line-height: 1.25
                margin-top: -4px
                font-size: 2rem
                margin-right: 4px
                color: $second-color


    &-button
        margin-top: 1rem

        @include max-width-lg
            display: block
            margin: auto
