.children

    &-header
        padding: 3rem 0 4rem
        background-image: url('../img/static/child.png'), linear-gradient(135deg, #F5FCFF 0%, rgba(194, 242, 233, 0.25) 100%)
        background-repeat: no-repeat
        background-position: center right
        background-size: contain

        @include max-width-lg
            background: linear-gradient(135deg, #FFDBF2 0%, rgba(194, 242, 233, 0.25) 100%)
            padding-bottom: 0
            // height: 580px

    &-about
        width: 470px

        @include max-width-lg
            width: auto
            display: flex
            flex-direction: column
            height: 100%

        &-image
            padding-top: 2rem
            display: none
            margin: auto
            width: 100%

            @include max-width-lg
                display: block

        &-title
            font-size: 2rem
            font-weight: 600

            & span
                color: #1B9BB8
                font-size: 2.1rem

        &-subtitle
            color: $gray-color-700
            font-size: 1.2rem
            margin-top: 1.5rem
            font-weight: 600

            @include max-width-lg
                margin-top: 0.75rem
                margin-bottom: 1rem

    &-advantages
        display: block
        margin-top: 1rem

        &-item
            display: flex
            align-items: center

            &-text
                margin-bottom: 0
                color: $gray-color-700
                font-size: 1.1rem

            &-icon
                line-height: 1.25
                margin-top: -4px
                font-size: 2rem
                margin-right: 8px
                color: #2EA3DA

    &-button
        margin-top: 1rem

        @include max-width-lg
            display: block
            margin: auto
            width: 100%
            margin-top: 2rem

    &-benefit

        &-wrapper
            display: flex
            flex-wrap: wrap
            justify-content: space-between
            gap: 1.25rem
            margin-top: 2rem

        &-item
            flex-basis: calc(25% - 1.25rem)

            @include max-width-xl
                flex-basis: calc(50% - 1.25rem)

            @include max-width-md
                flex-basis: calc(100% - 1.25rem)

        &-block
            display: flex
            gap: 1rem

            &-img
                height: 64px
                width: 64px

            &-title
                color: #333335
                font-family: Manrope
                font-size: 18px
                font-style: normal
                font-weight: 600
                line-height: 120%
                margin-bottom: 4px

            &-text
                color: #646468
                margin-bottom: 0

    &-service

        &-wrapper
            display: flex
            flex-wrap: wrap
            justify-content: space-between
            gap: 1.25rem
            margin-top: 2rem

            @include max-width-xl
                flex-direction: column

        &-item
            display: flex
            gap: 1.5rem
            align-items: flex-start
            flex-basis: calc(50% - 1.25rem)
            background-color: #F5F6FF
            border-radius: 8px
            padding: 32px 40px

            @include max-width-md
                flex-direction: column
                flex-basis: 100%

            &-img
                width: 200px

            &-data

                &-title
                    color: #333335
                    font-family: Manrope
                    font-size: 18px
                    font-style: normal
                    font-weight: 600
                    line-height: 120%

                &-list
                    color: #646468

    &-why
        border-radius: 8px
        background: linear-gradient(134deg, #FEFEFE 34.59%, #E5F2F9 100%)
        padding: 2rem 0
        overflow: hidden

        @include max-width-lg
            background: none
            padding: 0

        &-section
            @include max-width-lg
                background: linear-gradient(134deg, #FEFEFE 34.59%, #E5F2F9 100%)

        &-wrapper
            display: grid
            grid-template-columns: 250px auto 250px
            gap: 2.5rem

            @include max-width-xl
                grid-template-columns: 250px auto 0rem

            @include max-width-lg
                display: flex
                flex-direction: column
                gap: 1rem

        &-medic
            display: block

            @include max-width-lg
                display: flex
                align-items: flex-end
                gap: 1rem

            &-img
                max-width: 250px

                @include max-width-lg
                    max-width: 50%

            &-name
                font-family: 'Manrope', sans-serif
                font-size: 1.1rem
                line-height: 1.2
                font-weight: 600
                margin-bottom: 5px
                margin-top: 1rem

                @include max-width-lg
                    font-size: 1.1rem

            &-position
                font-size: 14px
                color: #8E8F94
                margin-bottom: 0

                @include max-width-lg
                    flex-basis: calc(50% - 1rem)

        &-info
            position: relative
            border-radius: 8px
            background-color: #1B9BB8
            padding: 2rem
            height: max-content

            &-title
                color: #fff
                font-family: Manrope
                font-size: 22px
                font-style: normal
                font-weight: 600

            &-text
                color: #fff
                font-size: 1.1rem
                line-height: 1.75

            &-button
                align-self: baseline

                &::after
                    content: 'Записаться на профилактический осмотр'

                @include max-width-md
                    width: 100%

                    &::after
                        content: 'Записаться'

            &-arrow
                position: absolute
                left: -2rem
                top: 10px
                width: 40px

                @include max-width-lg
                    display: none

        &-portret
            display: flex
            align-items: flex-end
            margin-bottom: -2rem

            @include max-width-xl
                display: none

            &-img
                width: 100%

    &-reviews
        background-color: #F5F6FF

        &-wrapper
            display: flex
            flex-wrap: wrap
            gap: 2rem
            margin-top: 2rem

            @include max-width-lg
                flex-direction: column

        &-item
            padding: 1.5rem
            border-radius: 8px
            border: 1px solid #C5F7FD
            background: #FFF
            box-shadow: 6px 6px 12px 0px rgba(217, 217, 217, 0.25)
            flex-basis: calc(25% - 1.5rem)

            @include max-width-xl
                flex-basis: calc(50% - 1.5rem)

            @include max-width-lg
                flex-basis: 100%

            &-header
                display: flex
                gap: 1rem
                margin-bottom: 1rem

                &-icon
                    display: flex
                    align-items: center
                    justify-content: center
                    height: 56px
                    width: 56px
                    border-radius: 50%
                    font-family: Manrope
                    font-size: 26px
                    font-style: normal
                    font-weight: 600
                    color: #000
                    align-items: center

                    &-first
                        background-color: #98DAF9

                    &-second
                        background-color: #98DAF9

                    &-third
                        background-color: #98DAF9

                    &-fourth
                        background-color: #98DAF9

                &-name
                    font-size: 18px
                    color: #333335
                    margin-bottom: 0.25rem

                &-count
                    margin-bottom: 0

                &-star
                    display: flex
                    gap: 1rem
                    align-items: center

            &-review
                font-size: 14px
                color: #646468
                margin-bottom: 0
