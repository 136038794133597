.articles

    &-wrapper
        display: flex
        flex-wrap: wrap
        gap: 2rem
        margin-top: 2rem

    &-show-more
        display: none
        margin-top: 2rem
        text-align: center

        &.--visible
            display: block

    &-item
        width: calc(33.333333% - 2rem)
        flex-basis: calc(33.333333% - 2rem)

        @include max-width-xl
            width: calc(50% - 2rem)
            flex-basis: calc(50% - 2rem)

        @include max-width-sm
            width: 100%
            flex-basis: 100%



        &-img
            height: 250px
            width: 100%
            object-fit: cover
            border-radius: 8px 8px 0 0