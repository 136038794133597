.reviews
    width: 100%
    height: 625px
    border: 0
    box-sizing: border-box

    &-wrapper
        display: flex
        gap: 2rem
        margin-top: 2rem

        @include max-width-lg
            display: block

    &-item
        flex-basis: calc(50% - 2rem)

        &.single
            flex-basis: 100%

        &:last-child
            @include max-width-lg
                border-top: 1px solid $separator-color
                margin-top: 2rem
                padding-top: 2rem

    &-page

        &-span
            color: #0E6187
            background-color: #C5EBFD
            padding: 8px 16px
            border-radius: 24px