.service-gnathology

    &-header
        position: relative
        padding: 3rem 0 4rem
        background: linear-gradient(90.14deg, #FFF 45.55%, rgba(245, 252, 255, 0) 62.54%)
        margin-bottom: 2rem

        @include max-width-lg
            background: linear-gradient(180deg, #FFF 44.35%, rgba(255, 255, 255, 0.00) 65.92%)
            min-height: 800px

        &-cover
            position: absolute
            right: 0
            top: 0
            height: 100%
            width: 55%
            z-index: -1

            @include max-width-lg
                margin-top: 350px
                width: 100%
                height: 400px

            &-img
                height: 100%
                width: 100%
                object-fit: cover

                @include max-width-lg
                    object-position: bottom

        &-data
            display: flex
            flex-direction: column
            justify-content: space-between

            &-list
                margin: 0.5rem 0 1.5rem
                display: flex
                flex-direction: column
                gap: 1rem

                &-item
                    display: flex
                    align-items: center
                    gap: 7px

                    &-text
                        color: #646468

            &-title
                font-family: Manrope
                color: #333335
                font-size: 32px
                font-weight: 500
                max-width: 400px
                margin: 1rem 0 1.5rem
                line-height: 130%

                @include max-width-lg
                    font-size: 20px

            &-subtitle
                font-size: 18px
                color: #646468
                margin-bottom: 1.5rem

        &-button
            margin-bottom: 2rem

    &-when
        display: grid
        grid-template-columns: repeat(2, 1fr)
        gap: 2rem

        @include max-width-lg
            grid-template-columns: repeat(1, 1fr)

        &-item

            &-data
                border-radius: 8px
                padding: 24px 16px 12px
                background: #f0f2fa
                height: 100%

            &-header
                display: flex
                align-items: center
                gap: 8px
                margin-bottom: 0.5rem

                &-img
                    height: 24px
                    width: 24px

                &-text
                    font-size: 18px
                    margin-bottom: 0

            &-text
                color: #646468
                margin-bottom: 0.375rem

            &-description
                color: #646468
                margin: 0.375rem 0 0

            &-list
                color: #646468
                margin-bottom: 0

                & li
                    margin-bottom: 0.375rem

                    &::last-child
                        margin-bottom: 0

            &-img
                width: 100%
                height: 100%
                object-fit: cover
                object-position: top left
                border-radius: 8px

    &-view

        &-img
            width: 100%
            height: 100%
            object-fit: cover
            object-position: top left
            border-radius: 8px
