.advantages

    &-wrapper
        display: none
        margin-top: 2rem
        // padding-left: 0.75rem
        // padding-right: 0.75rem
        padding-bottom: 1rem
        overflow-x: scroll
        // scrollbar-width: none
        // -ms-overflow-style: none

        &::-webkit-scrollbar-track
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3)
            border-radius: 10px
            background-color: #E8E9F2

        &::-webkit-scrollbar
            height: 4px
            border-radius: 10px
            background-color: #E8E9F2

        &::-webkit-scrollbar-thumb
            border-radius: 10px
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3)
            background-color: #2798CD

        // &::-webkit-scrollbar
        //     display: none
        //     width: 0
        //     height: 0

        // @include min-width-sm
        //     padding-left: calc(((100vh - 540px) / 2) + 0.75rem)
        //     padding-left: calc((((var(--vw, 1vw) * 100) - 540px) / 2) + 0.75rem)
        //     padding-right: calc(((100vh - 540px) / 2) + 0.75rem)
        //     padding-right: calc((((var(--vw, 1vw) * 100) - 540px) / 2) + 0.75rem)

        // @include min-width-md
        //     padding-left: calc(((100vh - 720px) / 2) + 0.75rem)
        //     padding-left: calc((((var(--vw, 1vw) * 100) - 720px) / 2) + 0.75rem)
        //     padding-right: calc(((100vh - 720px) / 2) + 0.75rem)
        //     padding-right: calc((((var(--vw, 1vw) * 100) - 720px) / 2) + 0.75rem)

        @include max-width-lg
            display: flex

    &-block
        display: flex
        gap: 15px
        width: max-content

    &-desktop
        display: flex
        justify-content: center

        @include max-width-lg
            display: none

        &-item
            display: block
            position: relative
            padding: 1rem
            // border-right: 1px solid $separator-color

            &::after
                content: ''
                position: absolute
                border-left: 1px solid $separator-color
                right: 0
                top: 20%
                height: 40%

            &:last-child::after
                border-left: none

            &-count
                font-size: 2rem
                font-weight: 600
                line-height: 1
                color: $second-color

            &-title
                color: $second-color
                margin-bottom: 0

            &-text
                font-size: 14px
                color: $gray-color-700

    &-item
        display: block
        text-align: left
        padding: 8px
        width: max-content
        background-color: #F5F6FF
        border-radius: 4px
        box-shadow: 6px 6px 12px rgba(217, 217, 217, 0.25)

        &-title
            color: $second-color
            margin-bottom: 0

            & span
                font-size: 22px
                margin-right: 6px

        &-text
            color: $gray-color-700
            font-size: 14px
            margin-bottom: 0
