.second

    &-breadcrumb
        // margin-top: 2rem

    &-page

        &-breadcrumb
            display: none

            @include max-width-lg
                display: block
                margin-top: 2rem

            &-desktop
                @include max-width-lg
                    display: none

        &-header
            position: relative
            // padding: 3rem 0 4rem 

            &-about
                width: 470px

                @include max-width-lg
                    width: auto

                &-title
                    font-size: 2rem

                &-address
                    font-size: 0.95rem
                    color: #646468
                    margin-bottom: 0

                    &-wrapper
                        display: flex
                        align-items: center
                        margin: 0.75rem 0 0.35rem

                        @include max-width-lg
                            // margin: 0.75rem 0

                    &-img
                        height: 16px
                        margin-right: 4px

                &-subway
                    font-size: 0.95rem
                    color: #8E8F94
                    margin-bottom: 0

                    &-wrapper
                        display: flex
                        gap: 1rem
                        align-items: center

                    &-block
                        display: flex
                        align-items: center
                        margin-top: 4px
                        margin-bottom: 1rem

                    &-img
                        height: 16px
                        margin-right: 4px

                &-phone

                    &-block
                        display: flex
                        align-items: center
                        margin: 0.25rem 0 0.75rem

                        @include max-width-lg
                            margin-bottom: 1.5rem

                    &-img
                        height: 16px
                        margin-right: 4px

                


            &-advantages
                display: block
                margin-top: 1rem

                @include max-width-lg
                    margin-bottom: 1rem

                &-item
                    display: flex
                    align-items: center

                    &-text
                        margin-bottom: 0
                        color: $gray-color-700

                    &-icon
                        line-height: 1.25
                        margin-top: -4px
                        font-size: 2rem
                        margin-right: 4px
                        color: $second-color

            &-cover
                position: absolute
                right: 0
                top: 0
                height: 100%
                width: 60%
                z-index: -1

                @include max-width-lg
                    position: relative
                    width: 100%
                    height: 400px

                &-img
                    height: 100%
                    width: 100%
                    object-fit: cover

                    @include max-width-md
                        display: none

                    &-clinic
                        height: 100%
                        width: 100%
                        object-fit: cover

                    &-mobile
                        display: none
                        height: 100%
                        width: 100%
                        object-fit: cover
                        object-position: center top

                        @include max-width-md
                            display: block

            &-data
                background: linear-gradient(90.14deg, #F5F5F5 47.55%, rgba(245, 252, 255, 0) 81.54%)
                padding: 2rem 0

                @include max-width-lg
                    background: linear-gradient(0.46deg, #E1E5E9 69.18%, rgba(225, 229, 233, 0.03) 98.76%)
                    margin-top: -10rem
                    padding-top: 10rem
                //     margin: 1rem
                //     width: calc(100% - 2rem)
                //     background-color: #F5F5F5
                //     border-radius: 8px
                //     margin-top: -10rem
