.braces-what

    &-block
        display: grid
        grid-template-columns: auto 350px

        @include max-width-lg
            display: flex
            flex-direction: column

    &-img
        width: 100%

        @include max-width-lg
            margin-top: 2rem

    &-quote
        position: relative
        background-color: #F0F2FA
        padding: 24px 32px
        box-shadow: 6px 0px 12px 0px rgba(217, 217, 217, 0.25)
        
        margin-right: 3rem
        margin-top: 2rem

        @include max-width-lg
            margin-right: 0

        &-text
            color: #646468
            font-size: 1.1rem
            margin-bottom: 0

            & span
                font-weight: 600

    &-arrow
        position: absolute
        top: 30px
        right: -3rem
        

        @include max-width-lg
            right: 30px
            top: initial
            bottom: -1rem
            transform: rotate(90deg)
            z-index: -1