$img-width: 260px
    
.implantation-why

    &-section
        background: linear-gradient(75.2deg, #FEFEFE 35.99%, #E5F6F9 87.75%, #B6E6F0 94.65%)

    &-block
        display: block

        @include max-width-lg
            display: flex
            gap: 1rem
            align-items: flex-end

    &-wrapper
        display: flex
        gap: 3rem

        @include max-width-lg
            gap: 1rem
            flex-basis: 40%

        @include max-width-md
            flex-basis: 50%

    &-item

        &-medic
            flex-basis: $img-width

            &-img
                width: 100%
                height: 100%
                object-fit: cover
                border-radius: 8px

            &-name
                font-family: 'Manrope', sans-serif
                font-size: 1.25rem
                line-height: 1.2
                font-weight: 600
                margin-bottom: 5px
                margin-top: 1rem

                @include max-width-lg
                    font-size: 1.1rem

            &-position
                font-size: 0.9rem
                color: #646468

                @include max-width-lg
                    margin-bottom: 0

            &-wrapper
                max-width: $img-width

                @include max-width-lg
                    flex-basis: 50%

        &-block
            display: flex
            flex-direction: column
            justify-content: center
            position: relative
            flex-basis: calc(100% - $img-width)
            background-color: #1B9BB8
            border-radius: 8px
            padding: 1.5rem 6rem 1.5rem 1.5rem
            margin-right: 10rem

            @include max-width-lg
                display: none

            &-arrow
                position: absolute
                left: -2rem
                top: 10px
                width: 40px

                @include max-width-lg
                    display: none

            &-title
                font-family: 'Manrope', sans-serif
                font-size: 1.1rem
                line-height: 1.2
                font-weight: 600
                color: #fff

            &-text
                color: #fff

            &-img
                position: absolute
                right: -164px
                top: 50%
                transform: translateY(-50%)
                width: 240px
                border-radius: 8px

            &-button
                align-self: baseline

    &-info

        &-block
            display: none
            gap: 1rem
            background-color: #1B9BB8
            border-radius: 8px
            padding: 1.5rem 2rem
            margin-top: 2rem
            margin-bottom: 2rem

            @include max-width-lg
                display: flex

            &-link
                text-decoration: none

        &-text
            color: #fff
            font-size: 20px
            margin-bottom: 0

        &-icon
            height: 24px

        &-button
            display: none
            margin: auto

            @include max-width-lg
                display: block

            @include max-width-sm
                width: 100%

        