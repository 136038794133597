.clinics

    &-block
        display: flex
        justify-content: space-between
        padding: 1.5rem
        background-color: #fff
        border-radius: 8px
        margin: 1.5rem 0

        @include max-width-lg
            flex-direction: column
            padding: 0

        &-data

            @include max-width-lg
                padding: 1rem

            &-link
                font-family: Manrope
                font-size: 18px
                font-weight: 600
                color: #333335

            &-address
                color: #646468
                text-decoration: underline
                margin-bottom: 0

                &-wrapper
                    display: flex
                    align-items: center
                    margin-top: 1.25rem

                    @include max-width-lg
                        margin-top: 0.75rem

                &-img
                    height: 16px
                    margin-right: 4px

            &-subway
                color: #646468
                margin-bottom: 0

                &-wrapper
                    display: flex
                    align-items: center
                    margin-top: 4px
                    margin-bottom: 1rem

                &-img
                    height: 16px
                    margin-right: 4px

                &-block
                    display: flex
                    gap: 12px

            &-phone
                font-size: 20px
                font-weight: 400
                color: #646468

            &-location
                color: $gray-color-700
                margin-top: 1rem
                margin-bottom: 12px

                &-wrapper
                    display: none

                    @include min-width-lg
                        display: block

                &-excellence
                    display: flex
                    margin-bottom: 10px

                    &-img
                        height: 20px
                        margin-right: 8px

                    &-text
                        font-size: 0.95rem
                        color: $gray-color-700
                        margin-bottom: 0

            &-buttons
                display: flex
                gap: 1rem
                margin-top: 1.5rem

                @include max-width-sm
                    flex-direction: column
                    gap: 0.75rem

        &-img
            position: absolute
            width: 100%
            height: 100%
            border-radius: 8px
            object-fit: cover

            @include max-width-lg
                border-top-left-radius: 0
                border-top-right-radius: 0
                border-bottom-right-radius: 6px
                border-bottom-left-radius: 6px
                object-position: top right

            &-wrapper
                position: relative
                width: 430px

                @include max-width-lg
                    width: 100%
                    height: 400px
                    margin-top: 0.75rem

                @include max-width-md
                    height: 250px

                @include min-width-xl
                    width: 600px

    &-excellence
        display: flex
        flex-wrap: wrap
        gap: 1rem
        margin-top: 2rem

        &-item
            flex-basis: calc(25% - 1rem)
            box-shadow: 6px 6px 12px rgba(217, 217, 217, 0.25)
            border-radius: 8px

            @include max-width-lg
                flex-basis: calc(50% - 1rem)

            @include max-width-sm
                flex-basis: 100%

            &-data
                background-color: #F8F8F8
                padding: 1rem
                height: calc(100% - 150px)
                border-radius: 0px 0px 8px 8px

            &-img
                height: 150px
                width: 100%
                object-fit: cover
                border-radius: 8px 8px 0px 0px

            &-title
                font-size: 1.1rem
                color: $second-color
                margin-top: 0.5rem

            &-text
                color: #646468

            &-link-wrapper
                text-align: right
