.documents

    &-wrapper
        margin-top: 2rem
        overflow-y: scroll
        scrollbar-width: none
        -ms-overflow-style: none

        &::-webkit-scrollbar
            display: none
            width: 0
            height: 0

    &-content
        display: flex
        gap: 12px
        width: max-content

        &-img
            width: 150px
            
    &-legal
        font-size: 18px
        color: #646468
        margin-bottom: 4px