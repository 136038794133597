.diagnostic
    position: relative
    padding: 60px 0

    @include max-width-xxl
        padding: 30px 0

    &-cover
        position: absolute
        right: 0
        top: 0
        height: 100%
        z-index: -1

        @include max-width-lg
            display: none

        &-img
            height: 100%
            width: 100%
            object-fit: cover
            object-position: center right

    &-data
        display: grid
        grid-template-columns: 400px 400px
        gap: 2rem

        @include max-width-lg
            grid-template-columns: calc(50% - 1rem) calc(50% - 1rem)

        @include max-width-md
            grid-template-columns: repeat(1, 1fr)
            gap: 0

        &-item
            background-color: #F0F2FA
            padding: 24px
            border-radius: 8px

            &:last-child
                @include max-width-md
                    border-top-right-radius: 0
                    border-top-left-radius: 0

            &:first-child
                @include max-width-md
                    border-bottom-right-radius: 0
                    border-bottom-left-radius: 0

            &-info
                font-size: 18px
                color: #333335

            &-diagnostic
                display: grid
                grid-template-columns: 150px auto
                align-items: center

                &-text
                    color: #84858A
                    font-family: Manrope
                    font-size: 18px
                    font-style: normal
                    font-weight: 600
                    margin-bottom: 0

                &-price
                    color: #2EA3DA
                    font-family: Manrope
                    font-size: 36px
                    font-style: normal
                    font-weight: 500
                    text-align: right
                    margin-bottom: 0

                &-button
                    width: 100%
                    margin-top: 2rem

    &-include

        &-header
            color: #2EA3DA
            font-family: Manrope
            font-size: 18px
            font-style: normal
            font-weight: 600

        &-item
            display: flex
            gap: 4px

            &-icon
                height: 24px
                width: 24px

            &-text
                color: #646468
