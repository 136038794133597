html
    box-sizing: border-box
    
*
    font-family: 'Source Sans Pro'
    transition: background 0.3s ease

*,
*::before,
*::after
    box-sizing: inherit

body
    margin: 0
    
h1, h2, h3, h4, h5, h6
    font-family: 'Manrope', sans-serif
    
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css")
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600&display=swap')
// @import url('https://db.onlinewebfonts.com/c/bb96cb60b7329b2f0d3303b6acc4219c?family=Sweet+Mavka+Script')