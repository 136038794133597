.patients

    &-wrapper
        display: grid
        grid-template-columns: repeat(2, 1fr)
        gap: 2rem
        margin-top: 2rem

        @include max-width-lg
            grid-template-columns: repeat(1, 1fr)

    &-item
        display: grid
        grid-template-columns: 50% 50%

        @include max-width-xl
            grid-template-columns: 60% 40%

        @include max-width-xs
            display: flex
            flex-direction: column

        &-data
            padding: 1rem

            @include max-width-xl
                padding: 0 1rem 0 0

            @include max-width-xs
                order: 2

            &-title
                color: #333335
                font-family: Manrope
                font-size: 18px
                font-style: normal
                font-weight: 600
                margin-bottom: 8px

            &-text
                color: #646468
                margin-bottom: 2rem

                @include max-width-xl
                    font-size: 14px

                @include max-width-xs
                    margin-bottom: 1rem

        &-img
            border-radius: 8px
            width: 100%

            @include max-width-xs
                order: 1
                margin-bottom: 1rem
