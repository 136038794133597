.initial-link
    color: #1B86B8
    text-decoration: none
    font-weight: 600

    &:hover
        color: #0E6187

    &:active
        color: #0E6187

.underline-link
    color: #1B86B8
    font-weight: 600

    &:hover
        color: #0E6187

    &:active
        color: #0E6187

.gray-link
    color: $gray-color
    text-decoration: none
    font-weight: 600

    &:hover
        color: #1373A0

    &:active
        color: #0E6187

