.callback

    &-medic

        &-img
            position: absolute
            right: 0
            bottom: -1rem
            height: 330px

            @include max-width-lg
                display: none

    &-form
        // max-width: 300px

    &-content
        padding: 1rem
        background-color: #F5FEFF !important
        border-radius: 16px !important
        border: 0 !important

        &-flat
            padding: 1rem
            border-radius: 16px !important
            border: 0 !important

    &-title
        font-family: 'Manrope', sans-serif
        font-size: 1.25rem
        font-weight: 500
        color: #333335
        margin-bottom: 0
        line-height: 1.2

        &-open-day
            font-family: 'Manrope', sans-serif
            font-size: 1.5rem
            font-weight: 500
            color: #333335
            margin-bottom: 1rem
            line-height: 1.2
            max-width: 450px

            & span
                font-size: 1.6rem
                color: #1B9BB8

    &-subtitle
        color: #646468

        &-open-day
            color: #333335
            font-size: 15px
            max-width: 390px

    &-input

        &-wrapper
            display: flex
            flex-direction: column
            gap: 12px
            margin-top: 1rem

    &-header
        display: flex
        justify-content: space-between
        gap: 1rem

    &-close
        // border: 1.5px solid #000 !important
        // border-radius: 50% !important
        // color: #C9CAD1 !important
        width: 10px !important
        height: 10px !important
        padding: .5em .5em !important
        opacity: 0.5
        flex: none
        // background: rgba(0,0,0,0) url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23C9CAD1'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat

    &-select
        font-size: 15px
        color: #646468
        margin-bottom: 0.375rem

    &-detail
        text-align: center
        margin-top: 0.75rem
