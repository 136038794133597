.price-policy

    &-wrapper
        display: flex
        gap: 2rem
        margin-top: 2rem
        
        @include max-width-lg
            gap: 1rem
            flex-direction: column

    &-item
        flex: 0 0 calc(33.333333% - 2rem)
        border-radius: 8px
        padding: 24px
        // margin-bottom: 1rem

        &.blue
            background-color: #E6FAFF

        &.pink
            background-color: #FFE6F2

        &.yellow
            background-color: #FFFCE6

        &-block
            display: block

            @include max-width-lg
                display: flex
                align-items: flex-start
                margin-bottom: 8px

        &-img
            height: 60px
            width: 60px
            margin-bottom: 1rem

            @include max-width-lg
                margin-bottom: 0
                margin-right: 12px

        &-title
            color: $second-color

        &-description
            font-size: 14px
            color: $gray-color-700
            margin-bottom: 0