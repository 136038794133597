.placeholder

    &-last-name
        padding: 0 120px
        border-radius: 8px
        animation: $animation
        line-height: 1.5

        @include max-width-lg
            padding: 0 30%

    &-first-name
        padding: 0 180px
        border-radius: 8px
        animation: $animation
        line-height: 1.5

        @include max-width-lg
            padding: 0 50%

    &-position
        padding: 0 200px
        border-radius: 8px
        animation: $animation
        line-height: 1.5

        @include max-width-lg
            padding: 0 40%

    &-experience
        padding: 0 60px
        border-radius: 8px
        animation: $animation
        line-height: 1.5

        @include max-width-lg
            padding: 0 20%

    &-place
        padding: 0 90px
        border-radius: 8px
        animation: $animation
        line-height: 1.5

        @include max-width-lg
            padding: 0 25%

    &-text
        padding: 10px 50%
        border-radius: 4px
        margin-bottom: 10px
        animation: $animation