.about

    &-top

        &-img
            float: right
            width: 300px
            margin: 0 0 15px 15px
            border-radius: 8px

            @include max-width-md
                float: none
                margin: 0.5rem 0 1rem 0

            @include max-width-sm
                width: 100%

        &-txt
            font-size: 18px

    &-wrapper
        display: grid
        grid-template-columns: 350px auto
        gap: 2rem

        @include max-width-xxl
            grid-template-columns: 380px auto

        @include max-width-xl
            grid-template-columns: 280px auto

        @include max-width-md
            grid-template-columns: repeat(1, 1fr)
            gap: 1rem

    &-item
        display: flex
        flex-direction: column
        align-items: baseline

        &-img
            width: 100%
            border-radius: 8px

        &-text
            font-family: Manrope
            font-size: 28px
            font-style: normal
            font-weight: 500
            line-height: 130%
            color: #333335
            margin-bottom: 2rem
            order: 1

            @include max-width-xxl
                font-size: 28px

            @include max-width-xl
                font-size: 24px

            @include max-width-lg
                font-size: 18px

            @include max-width-md
                order: 3

        &-name
            color: #333335
            font-family: Manrope
            font-size: 18px
            font-weight: 600
            margin-bottom: 4px
            order: 2

            @include max-width-md
                order: 1

        &-position
            color: #646468
            max-width: 300px
            margin-bottom: 1.5rem
            order: 3

            @include max-width-md
                order: 2

        &-button
            order: 4