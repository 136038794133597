.medical

    &-header
        font-size: 1.75rem
        color: $header-text-color
        margin-bottom: 8px

    &-description
        color: $gray-color-700
        max-width: 600px

    &-button-wrapper
        // margin-top: 1.5rem
        text-align: center

    &-block

        &-buttons
            display: flex
            gap: 12px
            width: max-content

            &-wrapper
                margin-top: 1rem
                overflow-y: scroll
                scrollbar-width: none
                -ms-overflow-style: none

                &::-webkit-scrollbar
                    display: none
                    width: 0
                    height: 0

    &-slides
        position: relative
        margin-top: 2rem

    &-slide
        position: relative
        display: flex !important
        flex-direction: column !important
        justify-content: space-between !important

        @include max-width-lg
            width: 200px !important

        &-experience
            position: absolute
            left: 8px
            top: 8px
            font-size: 13px
            color: #2EA3DA
            background-color: #FFFFFF
            padding: 3px 8px
            border-radius: 24px
            z-index: 1

        &-img
            width: 100%
            height: 280px
            border-radius: 8px
            object-fit: cover
            margin-bottom: 8px
            background-color: #F8F8F8
            border-style: none
            clip-path: inset(0.6px)

        &-first-name, &-last-name
            font-family: Manrope
            font-size: 18px
            margin-bottom: 0
            color: #333335
            font-weight: 600
            line-height: 130%

        &-job-title
            color: #646468
            margin-top: 4px
            margin-bottom: 0.375rem

    &-pagination-wrapper

        &::before
            content: ''
            padding: 50px
