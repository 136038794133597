.swal2-styled.swal2-confirm
	background-color: $primary-color !important

.swal2-popup .swal2-title
	font-family: 'Source Sans Pro'
	font-size: 1.1rem !important

.swal2-popup .swal2-content
	font-family: 'Source Sans Pro'
	font-size: 1.1rem !important
	font-weight: 500 !important

.swal2-loader
	border-color: $primary-color transparent $primary-color transparent !important

.swal2-html-container
	font-weight: 500 !important