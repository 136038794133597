.fill-button
    color: #fff
    background-color: #2798CD
    border-color: #2798CD
    font-size: 1rem
    font-weight: 400
    border: 1px solid transparent
    border-radius: 24px
    padding: 6px 16px
    cursor: pointer

    &.link
        text-decoration: none

        &:hover
            text-decoration: none
            color: #fff

    &:hover
        background-color: #1373A0
        border-color: #1373A0

    &:active
        background-color: #0E6187
        border-color: #0E6187

.outline-button
    color: #1B86B8
    background-color: #fff
    // border-color: #1B86B8
    font-size: 1rem
    font-weight: 400
    border: 1px solid #1B86B8
    border-radius: 24px
    padding: 6px 16px
    cursor: pointer
    text-decoration: none

    &.link
        text-decoration: none

        &:hover
            text-decoration: none
            color: #fff

    &:hover
        color: #fff
        background-color: #1B86B8
        border-color: #1B86B8

    &:active
        color: #fff
        background-color: #0E6187
        border-color: #0E6187
        
.active-button
    color: #0E6187
    background-color: #F5FCFF
    border-color: #F5FCFF
    font-size: 1rem
    font-weight: 400
    border: 1px solid transparent
    border-radius: 24px
    padding: 6px 16px
    cursor: pointer

    &:hover
        background-color: #C5EBFD

    &:active
        background-color: #b4e4fb

    &.active
        background-color: #1B86B8
        color: #FFF

.link-button
    color: $gray-color
    background-color: transparent
    border-color: transparent
    font-size: 1rem
    font-weight: 600
    border: none
    padding: 0
    cursor: pointer
    
    &:hover
        color: #1373A0

    &.active
        color: #0E6187
        text-decoration: underline