.section
    padding: 2rem 0

    &.second
        background-color: #F4F4F4

    &.color
        background-color: #F5FCFF

    &-title
        font-family: Manrope
        font-size: 32px
        font-style: normal
        font-weight: 500
        line-height: 120%
        max-width: 70%
        color: #333335

        @include min-width-xxl
            font-size: 36px

        @include max-width-lg
            font-size: 24px

        @include max-width-md
            max-width: 100%

        // & span
        //     color: #1B9BB8
        //     font-weight: 600
        //     font-size: 1.85rem

        //     @include min-width-xxl
        //         font-size: 2rem

        // & span.section-title-second
        //     color: #AD4682

    &-subtitle
        color: $gray-color-700
        max-width: 70%

        @include max-width-md
            max-width: 100%

    &.--hidden
        display: none
