.feedback

    &-wrapper
        margin-top: 2rem

    &-section
        margin-bottom: -4rem

    &-block
        display: flex
        flex-wrap: wrap
        align-items: center
        margin-top: 2rem
        gap: 1rem

    &-title
        font-family: Manrope
        font-size: 20px
        font-weight: 500
        color: #333335

    &-item
        position: relative
        border-radius: 8px
        border: 2px solid #C5F7FD
        padding: 1rem
        text-align: center
        flex-basis: calc(25% - 1rem)

        @include max-width-lg
            flex-basis: calc(50% - 1rem)

        @include max-width-md
            flex-basis: calc(100% - 1rem)

        &:hover
            border-color: #98EFF9
            box-shadow: 6px 6px 12px 0px rgba(217, 217, 217, 0.25)

        &-link

            &::after
                content: ''
                position: absolute
                top: 0
                left: 0
                right: 0
                bottom: 0
                z-index: 1

        &-logo
            height: 32px
            width: 100%
            margin-bottom: 0.5rem
            object-fit: contain

        &-name
            font-size: 1.1rem
            color: #333335
            margin-bottom: 0.75rem

    