.breadcrumb

    &-item a
        text-decoration: none

        &:hover
            color: #1B86B8

    &-placeholder
        position: absolute
        width: 90px
        height: 12px
        margin: 5px 0
        border-radius: 3px
        animation: $animation