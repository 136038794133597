.swiper
    width: 100%
    height: 100%

    &-control
        position: relative
        display: none
        gap: 1.5rem
        align-items: center
        justify-content: center
        padding-top: 2.5rem

        &.--visible
            display: flex

    &-pagination
        width: auto !important
        color: $gray-color !important
        position: relative !important
        bottom: 11px !important
        margin-bottom: 1.5rem
        // position: absolute !important
        // bottom: -25px !important

        &.--visible
            display: block

        &.swiper-pagination-dynamicBullets
            position: absolute !important

    &-button-prev
        position: relative !important
        background-image: url('../img/static/arrow-left.svg')
        background-repeat: no-repeat
        background-position: center center
        height: 40px !important
        width: 40px !important
        border-radius: 50%
        box-shadow: 6px 6px 12px rgba(217, 217, 217, 0.25)
        margin-bottom: 1.5rem
        background-color: #fff

        &::after
            content: '' !important

    &-button-next
        position: relative !important
        background-image: url('../img/static/arrow-right.svg')
        background-repeat: no-repeat
        background-position: center center
        height: 40px !important
        width: 40px !important
        border-radius: 50%
        box-shadow: 6px 6px 12px rgba(217, 217, 217, 0.25)
        margin-bottom: 1.5rem

        &::after
            content: '' !important

    &-slide
        height: auto !important
        justify-content: space-between
        // @include max-width-lg
        //     width: 200px !important
//         text-align: center
//         font-size: 18px
//         background: #fff
//         display: flex
//         justify-content: center
//         align-items: center
//         overflow: visible

.swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled
    opacity: 0.65 !important
    background-color: #fff !important