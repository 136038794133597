.slick-dots
	bottom: -10px !important

.slick-dots li img:nth-child(1) 
	display: block
	margin-top: -20px

.slick-dots li img:nth-child(2) 
	display: none
	margin-top: -20px

.slick-dots li.slick-active img:nth-child(1) 
	display: none
	margin-top: -20px

.slick-dots li.slick-active img:nth-child(2) 
	display: block
	margin-top: -20px