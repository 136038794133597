.service-implantation

    &-header
        position: relative
        padding: 3rem 0 4rem
        background: linear-gradient(90.14deg, #FFF 45.55%, rgba(245, 252, 255, 0) 62.54%)
        margin-bottom: 2rem

        @include max-width-lg
            background: linear-gradient(180deg, #FFF 44.35%, rgba(255, 255, 255, 0.00) 65.92%)
            min-height: 800px

        &-cover
            position: absolute
            right: 0
            top: 0
            height: 100%
            width: 55%
            z-index: -1

            @include max-width-lg
                margin-top: 350px
                width: 100%
                height: 400px

            &-img
                height: 100%
                width: 100%
                object-fit: cover

                @include max-width-lg
                    object-position: bottom

        &-data
            display: flex
            flex-direction: column
            justify-content: space-between

            &-title
                font-family: Manrope
                color: #333335
                font-size: 32px
                font-weight: 500
                max-width: 400px
                margin: 1rem 0 1.5rem
                line-height: 130%

                @include max-width-lg
                    font-size: 20px

            &-subtitle
                font-size: 18px
                color: #646468
                margin-bottom: 1.5rem

        &-button
            margin-bottom: 2rem

    &-kind

        &-installment

            &-wrapper
                display: flex
                justify-content: space-between
                flex-wrap: wrap
                gap: 1rem
                margin-top: 1rem

                @include max-width-lg
                    display: none

            &-item
                background: #C5F7FD
                box-shadow: 6px 6px 12px rgba(217, 217, 217, 0.25)
                border-radius: 8px
                padding: 1rem
                text-align: center
                flex-basis: calc(33.333333% - 1rem)

                &-text
                    margin-bottom: 0
                    font-size: 0.8rem

                    @include min-width-xl
                        font-size: 0.95rem

                    @include min-width-xxl
                        font-size: 1rem

                &-info
                    font-weight: 600
                    margin-bottom: 0

                    & span
                        color: #A0136C

        &-wrapper
            display: flex
            justify-content: space-between
            flex-wrap: wrap
            gap: 1rem
            padding-top: 1rem

            @include max-width-lg
                justify-content: center

        &-item
            display: flex
            flex-direction: column
            background: #FFFFFF
            box-shadow: 6px 6px 12px rgba(217, 217, 217, 0.25)
            border-radius: 8px
            flex-basis: calc(33.333333% - 1rem)
            overflow: hidden

            @include max-width-lg
                flex-basis: 51%

            @include max-width-sm
                flex-basis: 100%

            &-img
                order: 1
                width: 100%

                @include max-width-lg
                    order: 2

        &-header
            display: flex
            justify-content: space-between
            padding: 1rem

            &-title
                font-family: 'Manrope', sans-serif
                font-size: 18px
                font-weight: 600
                color: #333335
                margin-bottom: 0

            &-subtitle
                font-size: 12px
                color: #84858A
                margin-bottom: 0

            &-img
                height: 24px

        &-price

            &-block
                display: flex
                justify-content: space-between
                align-items: center
                gap: 1rem
                padding: 1rem
                background-color: #E0F5FF
                order: 2

                @include max-width-lg
                    order: 1

            &-text
                font-size: 16px
                color: #333335
                margin-bottom: 0

                @include max-width-sm
                    // max-width: 140px

            &-data
                flex: none
                text-align: right

                &-cost
                    font-family: 'Manrope', sans-serif
                    font-size: 22px
                    font-weight: 700
                    color: #333335
                    margin-bottom: 0
                    line-height: 1.2

                &-sale
                    font-size: 13px
                    color: #8E8F94
                    margin-bottom: 0
                    text-decoration: line-through

                    &-block
                        display: flex
                        align-items: center
                        gap: 4px

                    &-offer
                        background: url('../img/static/offer-line.svg') no-repeat center center
                        color: #fff
                        font-size: 11px
                        width: 16px
                        height: 16px
                        padding-right: 3px

        &-offer

            &-title 
                color: #333335
                margin-bottom: 0
                font-family: 'Manrope', sans-serif
                font-size: 18px
                font-weight: 600

                &-block
                    display: none

                    @include max-width-lg
                        display: block
                        flex-basis: 51%

                    @include max-width-sm
                        flex-basis: 100%

            &-block
                padding: 1rem
                order: 3

                @include max-width-lg
                    display: none

            &-mobile

                &-block
                    display: none
                    padding: 1rem
                    background-color: #fff
                    box-shadow: 6px 6px 12px rgba(217, 217, 217, 0.25)
                    border-radius: 8px

                    @include max-width-lg
                        display: block
                        flex-basis: 51%

                    @include max-width-sm
                        flex-basis: 100%

            &-item
                display: flex
                justify-content: space-between
                align-items: center
                gap: 1rem
                border-bottom: 1px solid #E8E9F2
                padding: 0.5rem 0

                &:first-child
                    padding-top: 0

                &:last-child
                    margin-bottom: 2rem

            &-button
                width: 240px
                display: block
                margin: auto

                @include max-width-xs
                    width: 100%

                &-wrapper
                    margin-top: 1rem

            &-text
                font-size: 16px
                color: #646468
                margin-bottom: 0

            &-sale
                font-size: 14px
                color: $second-color
                margin-bottom: 0
                text-decoration: underline

            &-span
                padding: 2px 8px
                border-radius: 4px
                color: #FFFFFF
                background-color: $second-color

            &-clause
                color: #333335
                margin-bottom: 0

            &-img
                height: 24px
                width: 24px

        &-validity

            &-block
                flex-basis: 100%
                margin-top: 1rem

                @include max-width-lg
                    display: block
                    flex-basis: 51%

                @include max-width-sm
                    flex-basis: 100%

            &-text
                font-size: 12px
                color: #8E8F94
                margin-bottom: 0

    &-solution
        display: grid
        grid-template-columns: 270px auto
        gap: 2rem

        @include max-width-lg
            display: block

        &-img
            width: 100%
            border-radius: 8px

            @include max-width-lg
                width: 50%

            @include max-width-xs
                width: 100%

            &-block
                display: block

                @include max-width-lg
                    display: flex
                    align-items: flex-end
                    gap: 1rem
                    margin-bottom: 1rem

                @include max-width-xs
                    display: block

        &-name
            font-family: 'Manrope', sans-serif
            font-size: 18px
            line-height: 1.2
            font-weight: 600
            margin-bottom: 5px
            margin-top: 1rem

            @include max-width-lg
                font-size: 1.1rem

        &-position
            font-size: 14px
            color: #8E8F94
            margin-bottom: 0

            @include max-width-lg
                flex-basis: calc(50% - 1rem)

        &-data

            &-title
                color: #333335
                font-family: Manrope
                font-size: 24px
                font-style: normal
                font-weight: 600
                max-width: 640px

                @include max-width-lg
                    display: none

                &-mobile
                    display: none
                    color: #333335
                    font-family: Manrope
                    font-size: 18px
                    font-style: normal
                    font-weight: 600

                    @include max-width-lg
                        display: block

            &-text
                color: #646468

            &-check
                font-size: 18px
                color: #646468
                margin-bottom: 0.5rem

                & i
                    color: #2EA3DA
                    margin-right: 4px
                    font-size: 16px

                &-title
                    color: #333335
                    font-family: Manrope
                    font-size: 18px
                    font-style: normal
                    font-weight: 600

            &-final
                color: #333335
                font-family: Manrope
                font-size: 18px
                font-style: normal
                font-weight: 600
                margin: 1rem 0

            &-button
                @include max-width-xs
                    width: 100%

        &-button
            @include max-width-lg
                display: block
                text-align: center

            

            &-wrapper
                margin-top: 1.5rem
