.braces-installment

    &-title
        max-width: 580px

    &-block
        position: relative
        width: max-content
        border-radius: 16px
        background: linear-gradient(135deg, #FFDBF2 0%, rgba(194, 220, 242, 0.25) 100%)
        padding: 24px 64px 24px 32px
        margin-top: 1.5rem

        @include max-width-lg
            width: 100%
            padding: 24px 32px 24px 32px

        &-title
            font-family: Manrope
            font-size: 1.5rem
            font-style: normal
            font-weight: 600
            line-height: 120%
            color: #1B9BB8
            max-width: 440px

            & span
                font-size: 1.65rem
                color: #A0136C

    &-img
        position: absolute
        top: 0
        right: -170px
        width: 230px

        @include max-width-lg
            width: auto
            height: auto
            max-width: 100%
            max-height: 100%
            left: 0
            top: 50%
            transform: translateY(-50%)

    &-part

        &-wrapper
            display: flex
            margin-bottom: 1rem

            @include max-width-sm
                justify-content: space-between

        &-item
            text-align: center
            border-right: 1px solid #646468
            padding-right: 1rem

            @include max-width-sm
                border-right: none

            &:not(:first-child)
                margin-left: 1rem

            &:last-child
                @include max-width-sm
                    display: none


        &-separator::before
            display: none

            @include max-width-sm
                content: ''
                border-right: 1px solid #646468  
                height: 100%
                display: block

        &-price
            color: #333335
            font-size: 1.5rem
            font-weight: 600
            margin-bottom: 0

            @include max-width-sm
                font-size: 5.5vw

        &-date
            color: #646468
            font-size: 0.9rem
            margin-bottom: 0

            @include max-width-sm
                font-size: 3.5vw

    &-include
        border-radius: 8px
        background-color: #1B9BB8
        padding: 8px 16px
        color: #fff
        font-weight: 600

        @include max-width-sm
            font-size: 12px

    &-footer
        @include max-width-lg
            display: grid
            grid-template-columns: auto 90px
            align-items: center
            gap: 1rem

        @include max-width-sm
            grid-template-columns: auto 50px

        &-img
            @include max-width-lg
                position: relative
                height: 100%
