.quality

    &-wrapper
        display: grid
        grid-template-columns: repeat(3, 1fr)
        gap: 2rem

        @include max-width-md
            grid-template-columns: repeat(1, 1fr)

    &-item
        position: relative
        padding: 1rem

        &::after
            content: ''
            position: absolute
            border-left: 1px solid $separator-color
            right: 0
            top: 15%
            height: 70%

            @include max-width-md
                border-left: none

        // &:nth-child(2)::after
        //     @include min-width-lg
        //         border-left: 1px solid $separator-color

        //     @include max-width-lg
        //         border-left: none

        &:last-child::after
            @include min-width-lg
                border-left: none

            @include min-width-sm
                border-left: none

            

        &-count
            font-family: Manrope
            font-size: 42px
            font-style: normal
            font-weight: 500
            color: #333335
            margin-bottom: 8px
            line-height: 130%

        &-description
            font-size: 18px
            color: #333335
            margin-bottom: 8px
            line-height: 120%

        &-text
            color: #646468
            margin-bottom: 8px
            line-height: 120%