.entry

    &-content
        padding: 1rem
        border-radius: 16px !important
        border: 0 !important

    &-header
        display: flex
        justify-content: space-between
        gap: 1rem

    &-close
        width: 10px !important
        height: 10px !important
        padding: .5em .5em !important
        opacity: 0.5
        flex: none

    &-title
        color: #333335
        font-family: Manrope
        font-size: 24px
        font-style: normal
        font-weight: 600
        line-height: 120%
        margin-bottom: 4px

    &-subtitle
        font-size: 17px
        color: #646468

    &-select
        color: #646468
        margin-bottom: 0.375rem