.service-tomography

    &-header
        position: relative
        padding: 3rem 0 4rem
        background: linear-gradient(90.14deg, #FFF 45.55%, rgba(245, 252, 255, 0) 62.54%)
        margin-bottom: 2rem

        @include max-width-lg
            background: linear-gradient(180deg, #FFF 44.35%, rgba(255, 255, 255, 0.00) 65.92%)
            min-height: 800px

        &-cover
            position: absolute
            right: 0
            top: 0
            height: 100%
            width: 55%
            z-index: -1

            @include max-width-lg
                margin-top: 350px
                width: 100%
                height: 400px

            &-img
                height: 100%
                width: 100%
                object-fit: cover

                @include max-width-lg
                    object-position: bottom

        &-data
            display: flex
            flex-direction: column
            justify-content: space-between

            &-list
                margin: 0.5rem 0 1.5rem
                display: flex
                flex-direction: column
                gap: 1rem

                &-item
                    display: flex
                    align-items: center
                    gap: 7px

                    &-text
                        color: #646468

            &-title
                font-family: Manrope
                color: #333335
                font-size: 32px
                font-weight: 500
                max-width: 400px
                margin: 1rem 0 1.5rem
                line-height: 130%

                @include max-width-lg
                    font-size: 20px

            &-subtitle
                font-size: 18px
                color: #646468
                margin-bottom: 1.5rem

        &-button
            margin-bottom: 2rem

    &-types
        display: grid
        grid-template-columns: repeat(2, 1fr)
        gap: 32px
        margin-top: 2rem

        @include max-width-md
            grid-template-columns: repeat(1, 1fr)

        &-header
            max-width: 592px

        &-item
            display: flex
            flex-direction: column
            border: 1px solid #c5ebfd
            border-radius: 8px
            box-shadow: 6px 6px 12px 0 rgba(217, 217, 217, 0.25)

            &-header
                display: flex
                align-items: center
                gap: 5px
                padding: 16px

                &-icon
                    height: 24px
                    width: 24px

                &-text
                    font-family: Manrope
                    font-weight: 600
                    font-size: 18px
                    color: #333335
                    letter-spacing: 0.01em
                    margin-bottom: 0

            &-info
                display: flex
                align-items: center
                border-radius: 0 0 8px 8px
                padding: 16px
                background: #f0f2fa
                height: 100%

                &-text
                    font-size: 15px
                    margin-bottom: 0
