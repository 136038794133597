.implantation-note

    &-wrapper
        display: flex
        gap: 1rem
        margin-top: 1.5rem

        @include max-width-xl
            flex-direction: column

    &-item
        display: flex
        align-items: center
        gap: 1rem
        flex-basis: 50%
        border-radius: 8px
        box-shadow: 6px 6px 12px rgba(217, 217, 217, 0.25)
        background-color: #fff
        overflow: hidden

        @include max-width-md
            flex-direction: column

        @include max-width-xl
            flex-basis: 100%

        &-img
            width: 185px
            height: 100%
            object-fit: cover

            @include max-width-md
                width: 100%
                height: 185px

        &-data
            padding: 1rem

            &-title
                font-size: 1.1rem
                font-weight: 600
                color: #A0136C

            &-text
                color: #333335

                & span
                    font-weight: 600

