.implantation-crown

    &-wrapper
        display: flex
        justify-content: space-between
        flex-wrap: wrap
        gap: 1rem

        @include max-width-lg
            justify-content: center

    &-item
        display: flex
        flex-direction: column
        background: #FFFFFF
        box-shadow: 6px 6px 12px rgba(217, 217, 217, 0.25)
        border-radius: 8px
        flex-basis: calc(33.333333% - 1rem)
        border-radius: 8px
        overflow: hidden

        @include max-width-lg
            flex-basis: 51%

        @include max-width-sm
            flex-basis: 100%

        &-img
            order: 1
            width: 100%

            @include max-width-lg
                order: 2

    &-header
        display: flex
        align-items: center
        justify-content: space-between
        padding: 1rem

        &-title
            font-family: 'Manrope', sans-serif
            font-size: 1.1rem
            font-weight: 600
            color: #A0136C
            margin-bottom: 0

        &-sale
            background-color: #A0136C
            border-radius: 4px
            padding: 3px 8px
            color: #fff
            font-size: 0.9rem

    &-price

        &-block
            display: flex
            justify-content: space-between
            align-items: center
            gap: 1rem
            padding: 1rem
            background-color: #C5F7FD
            order: 2

            @include max-width-lg
                order: 1

        &-text
            font-size: 14px
            color: #333335
            margin-bottom: 0

            @include max-width-sm
                max-width: 140px

        &-data
            flex: none
            text-align: right

            &-cost
                font-family: 'Manrope', sans-serif
                font-size: 1.2rem
                font-weight: 600 
                color: #333335
                margin-bottom: 0
                line-height: 1.2

            &-sale
                font-size: 13px
                color: #8E8F94
                margin-bottom: 0
                text-decoration: line-through

                &-block
                    display: flex
                    align-items: center
                    gap: 4px

                &-offer
                    background: url('../img/static/offer-line.svg') no-repeat center center
                    color: #fff
                    font-size: 11px
                    width: 16px
                    height: 16px
                    padding-right: 3px

    &-offer

        &-title
            color: $second-color
            margin-bottom: 0
            font-family: 'Manrope', sans-serif
            font-weight: 500

            &-block
                display: none

                @include max-width-lg
                    display: block
                    flex-basis: 51%

                @include max-width-sm
                    flex-basis: 100%

        &-block
            display: flex
            flex-direction: column
            justify-content: space-between
            height: 100%
            padding: 1rem
            order: 3

            @include max-width-lg
                display: none

        &-mobile

            &-block
                display: none
                padding: 1rem
                background-color: #fff
                box-shadow: 6px 6px 12px rgba(217, 217, 217, 0.25)
                border-radius: 8px

                @include max-width-lg
                    display: block
                    flex-basis: 51%

                @include max-width-sm
                    flex-basis: 100%

        &-item
            display: flex
            justify-content: space-between
            align-items: center
            gap: 1rem
            border-bottom: 1px solid #E8E9F2
            padding: 0.5rem 0

            &:first-child
                padding-top: 0

        &-button
            margin-top: 1rem
            width: 100%

        &-text
            font-size: 14px
            color: #646468
            margin-bottom: 0

            &-bold
                font-weight: 500
                font-size: 14px
                color: #333335
                margin-bottom: 0

        &-sale
            font-size: 14px
            color: $second-color
            margin-bottom: 0
            text-decoration: underline

        &-span
            padding: 2px 8px
            border-radius: 4px
            color: #FFFFFF
            background-color: $second-color

        &-clause
            font-size: 14px
            color: $second-color
            margin-bottom: 0

        &-img
            height: 18px
            width: 18px