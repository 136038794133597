.footer
    background-color: #07242B

    &-logo
        width: 160px
        margin-right: 3rem

    &-container
        display: flex
        align-items: flex-start
        padding: 30px 0

        @include max-width-lg
            flex-wrap: wrap

        &-content
            display: block

    &-menu
        display: flex
        gap: 1.375rem

        @include max-width-lg
            margin-top: 2rem
            gap: 0.75rem
            flex-wrap: wrap

        @include min-width-xl
            gap: 2rem

    &-nav-link
        font-weight: 600
        color: #C9CAD1
        text-decoration: none

        &:last-child
            display: none

            @include max-width-lg
                display: block

        @include max-width-lg
            flex: calc(50% - 0.75rem)

        &:hover
            color: #FFFFFF

    &-content
        width: 100%

    &-data
        display: flex
        gap: 2rem
        margin-top: 2rem

        @include max-width-lg
            flex-direction: column
            flex-wrap: wrap

        &-contacts
            height: max-content
            
            // &:last-child
            //     margin-top: 2rem

                @include max-width-lg
                    margin-top: 0

                @include max-width-sm
                    margin-top: 2rem

            &-top

                &-block
                    display: flex
                    gap: 1rem
                    align-items: center

        &-item
            display: block

            &.contacts
                // flex: 0 0 calc(33.333333% - 2rem)
                flex-basis: 40%

                @include max-width-lg
                    display: grid
                    grid-template-columns: repeat(1, 1fr)

                @include max-width-sm
                    display: block

            &.feedback
                // flex: 0 0 calc(33.333333% - 2rem)
                flex-basis: 26%

                @include min-width-xl
                    flex-basis: 30%

                @include max-width-lg
                    flex-basis: 100%
                    // text-align: center


                @include max-width-sm
                    text-align: left

            &.info
                // flex: 0 0 calc(33.333333% - 2rem)
                flex-basis: 34%

                @include min-width-xl
                    flex-basis: 30%

                @include max-width-lg
                    flex-basis: 100%
                    // text-align: center


                @include max-width-sm
                    text-align: left

        &-subway
            display: flex
            align-items: center
            margin-top: 8px

            &-img
                height: 16px
                margin-right: 4px

            &-text
                color: #FFFFFF
                margin-bottom: 0

            &-wrapper
                display: flex
                gap: 12px

        &-phone
            color: #FFFFFF
            text-decoration: none

            &:hover
                color: #C9CAD1

        &-name
             color: #C9CAD1
             margin: 8px 0 0
            
        &-location
            font-size: 0.95rem
            color: #C9CAD1
            margin-bottom: 0

        &-email
            color: #FFFFFF
            text-decoration: none
            font-size: 0.9rem
            margin-bottom: 1rem

            &-wrapper
                margin-bottom: 4px

                    

            &:hover
                color: #C9CAD1

        &-chief
            color: #FFFFFF
            font-size: 0.9rem
            text-decoration: underline

            &-wrapper
                margin-bottom: 4px

            &:hover
                color: #C9CAD1

        &-social
            color: #FFFFFF
            margin-top: 6rem
            margin-bottom: 4px
            font-size: 0.9rem

            @include max-width-lg
                margin-top: 2rem

            &-wrapper
                display: flex
                gap: 1rem  

                @include max-width-lg
                    display: flex
                    justify-content: flex-start


                @include max-width-sm
                    justify-content: flex-start

            &-icon
                width: 40px
                height: 40px

        &-info
            @include max-width-lg
                margin-top: 2rem

            &-link
                font-size: 0.9rem
                color: #8E8F94

                &:hover
                    color: #FFFFFF

        &-payment

            &-wrapper
                margin-top: 2rem

                @include max-width-lg
                    margin-top: 2rem

            &-img
                height: 32px

    &-info
        color: #C9CAD1
        font-size: 0.75rem
        padding-bottom: 1rem
        margin-bottom: 0

        &-data
            color: #C9CAD1
            font-size: 0.9rem
            margin-bottom: 0.25rem

            &.first
                margin-top: 0.75rem
            
            