.braces-info

    &-section
        background: linear-gradient(165deg, #FFF 19.40%, #E8E7E7 83.90%)

    &-wrapper
        display: flex
        margin-top: 2rem
        // padding-left: 0.75rem
        // padding-right: 0.75rem
        padding-bottom: 1rem
        overflow-x: scroll

        @include min-width-lg
            overflow: hidden

        &::-webkit-scrollbar-track
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3)
            border-radius: 10px
            background-color: #E8E9F2

        &::-webkit-scrollbar
            height: 4px
            border-radius: 10px
            background-color: #E8E9F2

        &::-webkit-scrollbar-thumb
            border-radius: 10px
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3)
            background-color: #1B9BB8

    &-block
        display: flex
        gap: 15px
        width: max-content

        @include min-width-lg
            display: grid
            grid-template-columns: repeat(4, 1fr)
            width: 100%

    &-item
        position: relative
        border-radius: 8px
        background-color: #FFF
        box-shadow: 6px 6px 12px 0px rgba(217, 217, 217, 0.25)

        @include max-width-lg
            width: 250px

        &-img
            width: 100%

        &-data
            padding: 1rem
            display: flex
            flex-direction: column
            justify-content: space-between
            

        &-text
            color: #AD4682
            font-family: Manrope
            font-size: 18px
            font-weight: 600
            
        &-span
            position: absolute
            bottom: 1rem
            left: 1rem
            width: 94px
            border: 2px solid #D1D1D1
      
    &-position
        width: 210px