.price

    &-header
        font-family: 'Manrope', sans-serif
        font-size: 1.25rem
        font-weight: 500
        color: #1B9BB8
        margin-top: 2rem

        &:first-child
            margin-top: 0

    &-block
        display: flex
        justify-content: space-between
        background-color: #F8F8F8
        border-radius: 8px
        padding: 16px
        gap: 16px
        cursor: pointer
        margin-bottom: 4px

        &[aria-expanded="true"] > .price-block-icon::after
            content: '–'
            margin-top: -4px

        &-text
            font-family: 'Manrope', sans-serif
            font-size: 18px
            font-weight: 600
            color: #333335
            margin-bottom: 0

        &-icon
            display: flex
            align-items: center
            justify-content: center
            width: 24px
            height: 24px
            border-radius: 50%
            background-color: #fff

            &::after
                content: '+'
                font-size: 1.5rem
                color: #1b86b8
    
        &-img
            width: 16px
            height: 16px

    &-collapse
        padding-left: 16px

        &-item 
            display: flex
            justify-content: space-between
            align-items: center
            gap: 24px
            border-bottom: 1px solid #AFB0B6
            padding: 16px 16px 16px 0

            &:last-child
                margin-bottom: 1rem

            &-name
                color: #646468
                margin-bottom: 0
               
            &-price
                margin-left: 16px
                margin-bottom: 0
                flex: none

                &.old
                    text-decoration: line-through

            &-sale
                flex: none
                background-color: #E0F5FF
                border-radius: 8px
                padding: 4px 8px
                color: #1B86B8
                margin-bottom: 0
                text-decoration: none

                & i
                    font-size: 14px
                    margin-left: 4px

                &-wrapper
                    display: flex
                    align-items: center
                    flex: none
                    gap: 2px

                    @include max-width-xs
                        flex-direction: column
                        align-items: flex-end
                        gap: 8px

    &-description
        font-size: 14px
        color: #646468
        margin-top: 2rem

    &-payment
        display: flex
        gap: 1rem
        margin: 2rem 0 1rem

        @include max-width-lg
            flex-direction: column

        &-item
            display: flex
            align-items: center
            gap: 16px
            padding: 16px
            flex-basis: calc(25% - 1rem)
            background-color: #E0F5FF
            // border: 1px solid #70E5F2
            box-shadow: 6px 6px 12px rgba(217, 217, 217, 0.25)
            border-radius: 8px

            &-img
                width: 35px
                height: 35px

            &-text
                line-height: 1.2
                color: #646468
                margin-bottom: 0

            &-link
                line-height: 1.2
                color: #646468