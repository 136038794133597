.form-callback

    &-title
        font-family: 'Manrope', sans-serif
        font-size: 1.25rem
        line-height: 1.2
        font-weight: 600
        color: #333335
        max-width: 70%

        @include max-width-lg
            max-width: auto

    &-subtitle
        color: #646468
        max-width: 70%

        @include max-width-lg
            max-width: auto

    &-wrapper
        position: relative
        padding-bottom: 5rem

        @include max-width-lg
            padding-bottom: 0

    &-medic

        &-block
            display: flex
            align-items: flex-end
            position: absolute
            right: 0
            bottom: -30px

            @include max-width-lg
                position: relative

            &.main
                @include max-width-lg
                    display: none
                

        &-data
            width: 305px

            @include max-width-md
                width: 50%

        &-img
            height: 320px

            @include min-width-xl
                height: 340px

            @include max-width-md
                width: 50%
                object-fit: contain

            @include max-width-lg
                height: 200px

        &-name
            font-family: 'Manrope', sans-serif
            font-size: 1.1rem
            line-height: 1.2
            font-weight: 600
            color: #333335
            margin-bottom: 5px

        &-position
            font-size: 0.85rem
            color: #646468
