.service-page

    &-header
        position: relative
        padding: 3rem 0 4rem
        // background-image: url('../img/static/header-implantation.png'), linear-gradient(101.66deg, #FFDBF2 -10.86%, rgba(194, 220, 242, 0.25) 107.7%)
        background-image: linear-gradient(101.66deg, #FFDBF2 -10.86%, rgba(194, 220, 242, 0.25) 107.7%)
        background-repeat: no-repeat
        background-position: center right
        background-size: contain
        overflow: hidden

        @include max-width-lg
            padding-bottom: 0

        &-img
            position: absolute
            right: 0
            bottom: 0
            width: 550px

            @include max-width-lg
                position: relative
                width: 100%
                max-width: 500px
                display: block
                margin: auto

            @include min-width-xl
                width: 700px

            @include min-width-xxl
                width: 780px
                top: 0
                bottom: initial


        &-title
            font-family: 'Manrope', sans-serif
            font-size: 2rem
            font-weight: 600
            line-height: 1.2
            max-width: 490px

            @include max-width-lg
                width: 100%

            & span
                color: #A0136C
                font-size: 2.1rem

        &-sale

            &-block
                display: flex
                align-items: center
                gap: 1rem
                background: rgba(255, 255, 255, 0.5)
                box-shadow: 6px 6px 12px rgba(217, 217, 217, 0.25)
                border-radius: 8px
                padding: 1rem
                width: 400px
                margin: 2rem 0

                @include max-width-lg
                    width: 100%

            &-img
                height: 122px

                @include max-width-lg
                    height: 100px

            &-data
                display: block

                &-title
                    font-family: 'Manrope', sans-serif
                    font-size: 1.5rem
                    font-weight: 600
                    line-height: 1.2
                    color: #A0136C
                    margin-bottom: 4px

                &-price
                    font-family: 'Manrope', sans-serif
                    font-size: 1.5rem
                    font-weight: 600
                    line-height: 1.2
                    margin-bottom: 8px

                &-include
                    font-size: 0.9rem
                    color: #646468
                    margin-bottom: 0

        &-quiz

            &-block
                position: relative
                width: 350px

                @include max-width-lg
                    width: 100%

            &-text
                max-width: 380px
                color: #333335

            &-gift
                position: absolute
                height: 80px
                right: -55px
                top: 50%
                transform: translateY(-50%)
                z-index: 1

                @include max-width-lg
                    display: none

            &-button
                display: block
                text-align: center
                width: 100%
                padding-right: 20px

    &-breadcrumb
        display: none

        @include max-width-lg
            display: block
            margin-top: 0.5rem

        &-desktop
            @include max-width-lg
                display: none

    